import React, { useEffect, useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppContainer, AsideContainer } from "../../Containers";
import { RequestFanbusy } from "../../Services";
import { Images, Metrics } from "../../Constants";
import { Avatar, Loader, CheckChat, CheckPremiumComponent } from "../../Components";
import { convertCurrencyBalance, useScreenSize } from "../../Utils";
import { Alert } from "../../Components";


const EditProfileView = () => {
	const [refreshKey, setRefreshKey] = React.useState(0);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const darkMode = useSelector((state) => state.theme.darkMode);
	const [plans, setPlans] = useState([]);
	const userDatas = useSelector((state) => state.users.users);
	const params = useSelector((state) => state.params);
	const token = useSelector((state) => state.users.access_token);
	const [scrollY, setScrollY] = useState(0);
	const [selectedBanner, setSelectedBanner] = useState();
	const [previewImageBanner, setPreviewImageBanner] = useState(userDatas.banner);
	const [selectedImageProfil, setSelectedImageProfil] = useState();
	const [previewImageProfil, setPreviewImageProfil] = useState(userDatas.photo);
	const [isFormModified, setIsFormModified] = useState(false);
	const [selectedCountryCode, setSelectedCountryCode] = useState("");
	const [isLoading, setIsloading] = useState(false);
	const screenSize = useScreenSize()
	const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
	let asianFee = convertCurrencyBalance(params.currency, params.currencies, userDatas?.subscriptions_details?.asian_fee?.month_fee, 'GET')
	let otherFee = convertCurrencyBalance(params.currency, params.currencies, userDatas?.subscriptions_details?.other_fee?.month_fee, 'GET')
	let africanFee = convertCurrencyBalance(params.currency, params.currencies, userDatas?.subscriptions_details?.african_fee?.month_fee, 'GET')

	const [state, setState] = React.useState({
		full_name: userDatas.full_name,
		display_name: userDatas.display_name,
		user_name: userDatas.pseudo,
		email: userDatas.email,
		phone_number: userDatas.phone_number,
		bio: userDatas.bio,
		photo: userDatas.photo,
		account_type: userDatas.account_type,
		banner: userDatas.banner,
		localisation: userDatas.localisation,
		web_site: userDatas.web_site,
		is_free_account: userDatas.is_free_account,
		subscription_fee: africanFee ? africanFee : 0,
		subscription_fee_world: otherFee ? otherFee : 0,
		subscription_fee_asian: asianFee ? asianFee : 0,
		active_tchat: userDatas.tchat_fee > 0,
		tchat_fee: convertCurrencyBalance(params.currency, params.currencies, userDatas.tchat_fee, 'GET'),
		currency: params.currency,
		isValidLink: true,
		isValidNumber: true,
		country_code: userDatas.country_code,
		isValidFee: false,
		isValidChatFee: false,
		isValidPseudo: true,
	});

	const [alert, setAlert] = React.useState({
		isActive: false,
		type: '',
		message: ''
	});

	const [check, setCheck] = useState({
		definedChatPrice: userDatas.tchat_fee > 0,
		definedSubscriptionPrice: userDatas?.subscriptions_details?.african_fee?.month_fee > 0
	});

	const [amount, setAmount] = React.useState({
		maxAmount: 0,
		minAmount: 0,
	});

	const handlePlanSave = (newPlans) => {
		setPlans(newPlans);
	};
	const handlePlanDelete = (index) => {
		setPlans((prevPlans) => prevPlans.filter((_, i) => i !== index));
	};


	React.useEffect(() => {
		let maxAmountPost = convertCurrencyBalance(params.currency, params.currencies, 200, 'GET')
		let minAmountpost = convertCurrencyBalance(params.currency, params.currencies, 1, 'GET')
		setAmount({ ...amount, maxAmount: maxAmountPost, minAmount: minAmountpost });

	}, [params.currencies, params.currency, amount]);

	const textInputchange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case "full_name":
				setIsFormModified(true);
				setState({ ...state, full_name: value });
				break;
			case "display_name":
				setIsFormModified(true);
				setState({ ...state, display_name: value });
				break;
			case "user_name":
				setIsFormModified(true);
				setState({ ...state, user_name: value });
				break;
			case "phone_number":
				const isValidNumber = Metrics.phoneRegex.test(value)
				setIsFormModified(true);
				setState({ ...state, phone_number: value, isValidNumber: isValidNumber });
				break;
			case "localisation":
				setIsFormModified(true);
				setState({ ...state, localisation: value });
				break;
			case "bio":
				setIsFormModified(true);
				setState({ ...state, bio: value });
				break;
			case "web_site":
				setIsFormModified(true);
				const isValidLink = value.trim().match(Metrics.urlRegex);
				setState({ ...state, web_site: value, isValidLink: isValidLink });
				break;
			case "country_code":
				setIsFormModified(true);
				setSelectedCountryCode(value);
				setState({ ...state, country_code: value });
				break;
			case "isFreeAccount":
				setIsFormModified(true);
				if (e.target.checked) {
					setCheck({ ...check, definedSubscriptionPrice: e.target.checked });
				} else {
					setCheck({ ...check, definedSubscriptionPrice: false });
					setState({ ...state, subscription_fee: 0 });
				}
				break;
			case "subscription_fee":
				setIsFormModified(true);
				if (value === "") {
					setState({ ...state, subscription_fee: 0 });

				} else {
					const africanFee = parseFloat(value);
					setState({ ...state, subscription_fee: africanFee });
				}
				break;
			case "activeTchat":
				setIsFormModified(true);
				if (e.target.checked) {
					setCheck({ ...check, definedChatPrice: e.target.checked });
				} else {
					setCheck({ ...check, definedChatPrice: false });
					setState({ ...state, tchat_fee: 0 });
				}
				break;

			case "tchat_fee":
				setIsFormModified(true);
				if (value === "") {
					setState({ ...state, tchat_fee: 0 });
				} else {
					const tchatFee = parseFloat(value);
					setState({ ...state, tchat_fee: tchatFee });
				}
				break;
			case "subscription_fee_asian":
				setState({ ...state, subscription_fee_asian: parseFloat(value) });
				break;
			case "subscription_fee_world":
				setState({ ...state, subscription_fee_world: parseFloat(value) });
				break;
			default:
				break;
		}
	};

	const handleChangeBanner = (event) => {
		const imageFile = event.target.files[0];
		setSelectedBanner(imageFile);
		setIsFormModified(true);
		const reader = new FileReader();
		reader.onload = () => { setPreviewImageBanner(reader.result); };
		reader.readAsDataURL(imageFile);
	};

	const handleChangeProfile = (event) => {
		const imageFile = event.target.files[0];
		setSelectedImageProfil(imageFile);
		setIsFormModified(true);
		const reader = new FileReader();
		reader.onload = () => {
			setPreviewImageProfil(reader.result);
		};
		reader.readAsDataURL(imageFile);
	};

	useEffect(() => {
		const checkIfPseudoIsAvailable = async (pseudo) => {
			try {
				const response = await RequestFanbusy("creators/u/" + pseudo, "GET", {}, token);
				if (response.status === 200) {
					setState({ ...state, isValidPseudo: true });
				} else {
					setState({ ...state, isValidPseudo: false });
				}
			} catch (error) {
			}
		};

		checkIfPseudoIsAvailable(state.pseudo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.pseudo, token]);


	const handleChangePhoneNumber = (value, meta) => {
		setState(prevState => ({ ...prevState, phone_number: value, country_code: meta.country.iso2 }));
		setIsFormModified(true);
	};

	async function updateSubscriptionDetail() {
		const reductions = {
			"3 months": "0",
			"6 months": "0",
			"12 months": "0"
		};

		const flattenedPlans = plans.flatMap(plan => Array.isArray(plan) ? plan : [plan]);

		flattenedPlans.forEach(plan => {
			if (reductions.hasOwnProperty(plan.duration)) {
				reductions[plan.duration] = plan.discount.replace('%', '');
			}
		});

		const quarterly_reduction = reductions["3 months"];
		const half_yearly_reduction = reductions["6 months"];
		const yearly_reduction = reductions["12 months"];

		const feeString = String(state.subscription_fee);
		const cleanedFee = feeString.replace(/[^0-9]/g, '');
		const amount = parseFloat(cleanedFee);

		const form = {
			african_fee: amount,
			asian_fee: amount,
			other_fee: amount
		}

		let response = await RequestFanbusy(`creators/subscription/details?currency=${params.currency}&quarterly_reduction=${quarterly_reduction}&half_yearly_reduction=${half_yearly_reduction}&yearly_reduction=${yearly_reduction}`, "PUT", form, token);
		if (response.status === 200) {
			// setIsLoding(false)
			const action = { type: "USERS", value: response.response.data };
			dispatch(action);
		} else if (response.response.status_code === 400 && response.response.description === "This pseudo is no longer available") {
			setAlert({ isActive: true, message: t('views.become.thisPseudoIsNoLongerAvailable'), type: "warning" });
		}
	}
	const validatePhoneNumber = (phoneNumber) => {
		return Metrics.phoneRegex.test(phoneNumber);
	};

	const submitEdit = async (event) => {
		event.preventDefault();
		setIsloading(true)

		if (!validatePhoneNumber(state.phone_number)) {
			setAlert({ isActive: true, message: t('views.become.validPhoneNumber'), type: "warning" });
			setIsloading(false)
			return
		}

		if (check.definedChatPrice && convertCurrencyBalance(params.currency, params.currencies, userDatas.tchat_fee, 'GET') !== state.tchat_fee && (state.tchat_fee > parseFloat(amount.maxAmount.replace(/\s/g, '')) || state.tchat_fee < parseFloat(amount.minAmount.replace(/\s/g, '')))) {
			setAlert({ isActive: true, message: t('views.editProfile.amountBetweenAnd') + ' ' + amount.minAmount + ' ' + t('words.and') + ' ' + amount.maxAmount, type: 'warning' })
			setIsloading(false)
			setRefreshKey(key => key + 1);
			return
		}

		if (check.definedSubscriptionPrice && convertCurrencyBalance(params.currency, params.currencies, userDatas.subscription_fee, 'GET') !== state.subscription_fee && (state.subscription_fee > parseFloat(amount.maxAmount.replace(/\s/g, '')) || state.subscription_fee < parseFloat(amount.minAmount.replace(/\s/g, '')))) {
			setAlert({ isActive: true, message: t('views.editProfile.amountBetweenAnd') + ' ' + amount.minAmount + ' ' + t('words.and') + ' ' + amount.maxAmount, type: 'warning' })
			setIsloading(false)
			setRefreshKey(key => key + 1);
			return
		}
		if ((check.definedChatPrice && state.tchat_fee === 0) || (check.definedSubscriptionPrice && state.subscription_fee === 0)) {
			setAlert({ isActive: true, message: t('navigation.pleaseEnterAValidNumber'), type: 'warning' })
			setRefreshKey(key => key + 1);

		} else {
			try {
				let bannerLink = "";
				let profilLink = "";
				if (selectedBanner instanceof File) {
					const formBanner = new FormData();
					formBanner.append("fileobject", selectedBanner);
					const bannerResponse = await RequestFanbusy("uploads/PROFILE", "POST", formBanner, token);
					if (bannerResponse.response && bannerResponse.response.data) {
						bannerLink = bannerResponse.response.data.link;
					}
				}
				if (selectedImageProfil instanceof File) {
					const formProfil = new FormData();
					formProfil.append("fileobject", selectedImageProfil);
					const profilResponse = await RequestFanbusy("uploads/PROFILE", "POST", formProfil, token);
					if (profilResponse.response && profilResponse.response.data) {
						profilLink = profilResponse.response.data.link;
					}
				}

				const form = {
					id: userDatas._id,
					display_name: state.display_name,
					pseudo: state.user_name,
					full_name: state.full_name,
					email: userDatas.email,
					country_code: selectedCountryCode || state.country_code,
					phone_number: state.phone_number,
					photo: profilLink || state.photo,
					banner: bannerLink || state.banner,
					bio: state.bio,
					id_type: userDatas.id_type,
					id_image: userDatas.id_image,
					id_card_image: userDatas.id_card_image,
					postal_code: userDatas.postal_code,
					street_number: userDatas.street_name,
					street_name: userDatas.street_name,
					city: userDatas.city,
					localisation: userDatas.localisation,
					web_site: userDatas.web_site,
					account_type: userDatas.account_type,
					is_free_account: userDatas.is_free_account,
					subscription_fee: state.subscription_fee,
					active_tchat: userDatas.active_tchat,
					tchat_fee: state.tchat_fee,
					tchat_fee_period: userDatas.tchat_fee_period,
					currency: params.currency,
					lang: userDatas.lang,
					verified: userDatas.verified,
					fan_number: userDatas.fan_number,
					follow_number: userDatas.follow_number,
					like_number: userDatas.like_number,
					verification_code: userDatas.verification_code,
					password: userDatas.password,
					tag: userDatas.tag,
					active_creator: userDatas.active_creator,
					have_stories: userDatas.have_stories,
					is_deleted: userDatas.is_deleted,
					created_at: userDatas.created_at,
					updated_at: userDatas.updated_at,
				};


				const response = await RequestFanbusy("creators/", "PUT", form, token);
				if (response.status === 200) {
					updateSubscriptionDetail();
					setIsloading(false)
					setAlert({ isActive: true, message: t('views.editProfile.yourProfileHasBeenSuccessfullyUpdated'), type: 'success' })
					// const action = { type: "USERS", value: response.response.data };
					// dispatch(action);
				}
				else {
					setIsloading(false)
					setAlert({ isActive: true, message: response.response.description, type: 'warning' })
					setRefreshKey(key => key + 1);

				}

				if (convertCurrencyBalance(params.currency, params.currencies, userDatas.tchat_fee, 'GET') !== state.tchat_fee) {
					const dataChat = {
						id: userDatas._id,
						display_name: userDatas.display_name,
						pseudo: userDatas.pseudo,
						full_name: userDatas.full_name,
						email: userDatas.email,
						country_code: userDatas.country_code,
						phone_number: userDatas.phone_number,
						photo: userDatas.photo,
						banner: userDatas.banner,
						bio: userDatas.bio,
						id_type: userDatas.id_type,
						id_image: userDatas.id_image,
						id_card_image: userDatas.id_card_image,
						postal_code: userDatas.postal_code,
						street_number: userDatas.street_name,
						street_name: userDatas.street_name,
						city: userDatas.city,
						localisation: userDatas.localisation,
						web_site: userDatas.web_site,
						account_type: userDatas.account_type,
						is_free_account: userDatas.is_free_account,
						subscription_fee: userDatas.subscription_fee,
						active_tchat: userDatas.active_tchat,
						tchat_fee: state.tchat_fee,
						tchat_fee_period: userDatas.tchat_fee_period,
						currency: params.currency,
						lang: userDatas.lang,
						verified: userDatas.verified,
						fan_number: userDatas.fan_number,
						follow_number: userDatas.follow_number,
						like_number: userDatas.like_number,
						verification_code: userDatas.verification_code,
						password: userDatas.password,
						tag: userDatas.tag,
						active_creator: userDatas.active_creator,
						have_stories: userDatas.have_stories,
						is_deleted: userDatas.is_deleted,
						created_at: userDatas.created_at,
						updated_at: userDatas.updated_at
					}
					const responseData = await RequestFanbusy('creators/tchat_fees', 'PUT', dataChat, token)
					if (responseData.status === 200) {
						const action = { type: "USERS", value: responseData.response.data };
						dispatch(action);
					}
				}
				if (convertCurrencyBalance(params.currency, params.currencies, userDatas.subscription_fee, 'GET') !== state.subscription_fee) {
					const dataSouscription = {
						id: userDatas._id,
						display_name: userDatas.display_name,
						pseudo: userDatas.pseudo,
						full_name: userDatas.full_name,
						email: userDatas.email,
						country_code: userDatas.country_code,
						phone_number: userDatas.phone_number,
						photo: userDatas.photo,
						banner: userDatas.banner,
						bio: userDatas.bio,
						id_type: userDatas.id_type,
						id_image: userDatas.id_image,
						id_card_image: userDatas.id_card_image,
						postal_code: userDatas.postal_code,
						street_number: userDatas.street_name,
						street_name: userDatas.street_name,
						city: userDatas.city,
						localisation: userDatas.localisation,
						web_site: userDatas.web_site,
						account_type: userDatas.account_type,
						is_free_account: userDatas.is_free_account,
						subscription_fee: state.subscription_fee,
						active_tchat: userDatas.active_tchat,
						tchat_fee: userDatas.tchat_fee,
						tchat_fee_period: userDatas.tchat_fee_period,
						currency: params.currency,
						lang: userDatas.lang,
						verified: userDatas.verified,
						fan_number: userDatas.fan_number,
						follow_number: userDatas.follow_number,
						like_number: userDatas.like_number,
						verification_code: userDatas.verification_code,
						password: userDatas.password,
						tag: userDatas.tag,
						active_creator: userDatas.active_creator,
						have_stories: userDatas.have_stories,
						is_deleted: userDatas.is_deleted,
						created_at: userDatas.created_at,
						updated_at: userDatas.updated_at
					}

					const responseDataS = await RequestFanbusy('creators/subscription_fee', 'PUT', dataSouscription, token)
					if (responseDataS.status === 200) {
						const action = { type: "USERS", value: responseDataS.response.data };
						dispatch(action);
					}
				}
				setIsloading(false)

			} catch (error) {
			}
		}
		setIsloading(false)
	};

	useEffect(() => {
		const handleScroll = () => {
			setScrollY(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function handlePrevent(e) {
		e.preventDefault();
	}

	return (
		<AppContainer>
			<main key={refreshKey} style={{ marginBottom: isMobile ? '20px' : '' }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  border border-top-0 border-fw-light px-0 border-bottom-0 ${isMobile && 'fixed-content pb-5'}`}>
				<div className="main-content ">
					{scrollY >= 260 && (
						<div style={{ height: '50px' }} className={` d-flex align-items-center mb-3 bg-white fix-sidebar top-0 border-bottom `}>
							<a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3 `} >
								arrow_back
							</a>
							<p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>{t('views.editProfile.editProfile')}</p>
						</div>
					)}
					<div className={`${darkMode ? 'bg-dark' : 'bg-white'} border-bottom profile position-relative pb-2  border-bottom`} style={{ position: 'relative' }}>
						<div style={{ backgroundImage: `url(${previewImageBanner ? previewImageBanner : userDatas.banner.length > 0 ? userDatas.banner : Images.background})`, height: "200px", backgroundSize: "cover" }}>
							<div style={{ backgroundColor: "rgba(0, 0, 0, .5)", height: "200px", }} >
								<header className="profile d-flex align-items-center pt-2">
									<div className="d-flex align-items-center">
										<a href={`/`} className={`text-white material-icons text-decoration-none ms-3`} > arrow_back </a>
										<p className={`text-white ms-2 mb-0 fw-bold fs-6 text-uppercase`}>{t('views.editProfile.editProfile')}</p>
									</div>
								</header>
							</div>
						</div>
						<div className="d-flex align-items-center px-3 pt-1">
							<div style={{ marginTop: -50 }}>
								<span style={{ marginLeft: "60px", marginTop: "55px", }} className="material-icons md-24 position-absolute top-10 end-80 fb-color">
									<input type="file" id="fileobject" accept="image/*" onChange={handleChangeProfile} className="position-absolute w-100 h-100" style={{ top: 0, left: 0, opacity: 0, cursor: "pointer" }} />
									photo_camera
								</span>
								<Avatar user={previewImageProfil ? previewImageProfil : userDatas} width={80} height={80} fontSize="fs-2" editPhoto={true} />
							</div>
						</div>
						<div style={{ position: "absolute", top: 5, right: 5 }}>
							<span className="material-icons md-24 me-1 fb-color " style={{ position: "relative" }} >
								photo_camera
								<input type="file" id="fileobject" accept="image/*" onChange={handleChangeBanner} className="position-absolute w-100 h-100" style={{ top: 0, left: 0, opacity: 0, cursor: "pointer" }} />
							</span>
						</div>
					</div>
					<div className="row justify-content-center mt-2">
						<div className="col-lg-12 pb-4">
							<form onSubmit={handlePrevent} className="px-2">
								<div className={`${darkMode && 'bg-dark'} form-floating mb-3 d-flex align-items-end`}>
									<input type="text" name="user_name" className={`${!state.isValidPseudo && 'border-danger'} form-control rounded-5`} onChange={textInputchange} value={state.user_name} placeholder="USERNAME" />
									<label htmlFor="floatingName">{t('views.editProfile.username')}</label>
								</div>

								<div className="form-floating mb-3 d-flex align-items-center">
									<input type="text" name="display_name" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} onChange={textInputchange} value={state.display_name} placeholder="DISPLAY NAME" />
									<label htmlFor="floatingBirth">{t('views.editProfile.displayName')}</label>
								</div>

								<PhoneInput
									inputClassName="w-100 "
									defaultCountry="cm"
									value={state.phone_number}
									onChange={handleChangePhoneNumber}
								/>

								<p className="d-flex justify-content-center">
									<small className={`d-flex ps-3 text-danger ${state.isValidNumber ? "d-none" : ""}`}>{t('views.editProfile.enterAValidInput')}</small>
								</p>
								{userDatas.account_type === "CREATOR" && (
									<>
										{/* <div className="form-floating my-3 d-flex align-items-center">
											<input type="text" name="localisation" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} onChange={textInputchange} placeholder="localisation" value={state.localisation} />
											<label htmlFor="floatingPass">{t('views.editProfile.adress')}</label>
										</div> */}
										<label className="pb-1" htmlFor="message">{t('views.editProfile.showcaseWhatKindOfContentYourFansWillBenefit')}</label>
										<div className="form-floating mb-3 d-flex align-items-center">
											<textarea type="text" name="bio" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5 pt-0`} onChange={textInputchange} placeholder="bio" value={state.bio} style={{ height: "10rem" }}></textarea>
										</div>
										<CheckPremiumComponent
											params={params}
											definedSubscriptionPrice={check.definedSubscriptionPrice}
											textInputchange={textInputchange}
											onSavePlans={handlePlanSave}
											subscriptionFee={state.subscription_fee}
											subscriptionFeeInAsian={state.subscription_fee_asian}
											subscriptionFeeInWorld={state.subscription_fee_world}
											isEditingProfile={true}
											onDeletePlan={handlePlanDelete}
										/>
										<CheckChat
											params={params}
											definedChatPrice={check.definedChatPrice}
											tchatFee={state.tchat_fee}
											currentTchatFee={userDatas.tchat_fee}
											textInputchange={textInputchange}
										/>
									</>
								)}
								{alert.isActive &&
									<Alert type={alert.type} msg={alert.message} />
								}
								<div className="d-grid">
									<button onClick={submitEdit} className="btn gradient-button text-white rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0" disabled={(!isFormModified) || isLoading}>
										{isLoading ? <Loader /> : `${t('views.editProfile.save')}`}
									</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</main>
			<AsideContainer />
		</AppContainer>
	);
};
export default EditProfileView;