import CardProfile from "./CardProfile"
import SubcriptionPlans from "./subscriptionPlan"

const SubscriptionsPlansModal = ({
    sref,
    refCloseSubscriptionPlansModal,
    checkIfSubscribeToCreator,
    isLoaded,
    subscribeToCreator,
    creator,
    t,
    pseudo,
    amountForMonth,
    amountForTreMonth,
    amountForSixMonth,
    amountForTwelvfMonth,
    releaseRef,
    isLoading3Months,
    isLoading6Months,
    isLoading12Months
}) => {

    return (
        <div className="modal fade" id="subscriptionPlansModal" tabIndex="-1" aria-labelledby="subscriptionPlansModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-web">

                <div className={`modal-content border-0 rounded-4`}>
                    <CardProfile creator={creator} subsciption={false} fan={false} subsPlan={true} />
                    <div className={`d-flex text-end  modal-header border-0 p-1 `}>
                        <button ref={refCloseSubscriptionPlansModal} type="button" className="btn btn-info bg-white text-primary border-0 py-0 my-0 material-icons " data-bs-dismiss="modal"></button>
                    </div>

                    <div className="modal-body pt-0 px-2" >
                        <div className="py-0 m-0">
                            <SubcriptionPlans
                                checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                isLoading={isLoaded}
                                creator={creator}
                                subscribeToCreator={subscribeToCreator}
                                t={t}
                                id={pseudo}
                                amountForMonth={amountForMonth}
                                amountForTreMonth={amountForTreMonth}
                                amountForSixMonth={amountForSixMonth}
                                amountForTwelvfMonth={amountForTwelvfMonth}
                                releaseRef={releaseRef}
                                isLoading3Months={isLoading3Months}
                                isLoading6Months={isLoading6Months}
                                isLoading12Months={isLoading12Months}
                                sref={sref}
                                title={false}
                                addClassParent={'mt-0'}
                            />
                        </div>
                    </div>
                    <button ref={refCloseSubscriptionPlansModal} type="button" className="btn btn-info bg-white text-primary border-0 py-0 my-0 text-end text-uppercase pb-2" data-bs-dismiss="modal">{t('words.close')}</button>
                </div>
            </div>
        </div>
    )
}
export default SubscriptionsPlansModal