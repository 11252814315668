import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './Store/configureStore';
import { WebSocketProvider } from './Services';

import App from './App';
import './assets/App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    //     <Provider store={store}>
    //         <PersistGate loading={null} persistor={persistor}>
    //             <WebSocketProvider>
    //                 <App />
    //             </WebSocketProvider>
    //         </PersistGate>
    //     </Provider>
    // </React.StrictMode>

    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <WebSocketProvider>
                <App />
            </WebSocketProvider>
        </PersistGate>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();