
import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { Popover } from 'bootstrap'; //Si cette ligne est supprimer , les drop bootsrap ne réagissent plus.
import { i18n, RequestFanbusy } from '../Services';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { GrFormClose } from 'react-icons/gr';
import { MdOutlineChat } from 'react-icons/md';


// Utilisation dans votre composant
import { BiBookmarks } from 'react-icons/bi';
import { MdOutlineDiversity1 } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { CgMoreAlt } from 'react-icons/cg';
import { TfiCreditCard } from 'react-icons/tfi';
import { BsCashStack } from 'react-icons/bs';
import { MdLanguage } from 'react-icons/md';
import { MdOutlineContactSupport } from 'react-icons/md';
import { MdOutlineDiversity2 } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { RiLogoutBoxLine, RiLoginBoxLine } from 'react-icons/ri';
import { RiHome5Line } from 'react-icons/ri';
import { RxAvatar } from 'react-icons/rx';
import { CgGift } from "react-icons/cg";

import { Avatar, Loader } from '../Components';
import { convertCurrency, shortenName } from '../Utils';
import { Images } from '../Constants';
// import { Svg } from '../Constants';
import { convertCurrencyBalance } from '../Utils/ApiRequest';

const NavigationBar = ({ roomUnread, CloseModals }) => {

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage;
        } catch (error) {
            return navigator.language || navigator.userLanguage;
        }
    });

    const darkMode = useSelector((state) => state.theme.darkMode);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const linkRef = React.useRef(null);
    const refNavModal = React.useRef(null);
    const [medias, setMedias] = React.useState([]);
    const [isShowProfile, setIsShowProfile] = React.useState(false);
    const [isPopoverVisible, setPopoverVisible] = React.useState(false);
    const [state, setState] = React.useState({
        postContent: '',
        mediaType: '',
        releaseErrors: '',
        is_free: false,
        price_of_release: 0,
        asFee: 0,
        oFee: 0,
        comment_disabled: false,
        likes: 0,
        Comments: 0,
        isLoading: true,
        isLoaded: false,
        updateLangResponse: '',
        postType: "HOME",
        collectionId: "",
        collections: []
    });

    const [isModalOpen, setIsModalOpen] = React.useState(true);

    const [amount, setAmount] = React.useState({
        maxAmount: 0,
        minAmount: 0,
    }
    );

    const [accountType, setAccountType] = React.useState(users?.users?.account_type);
    const [isdarkMode, setIsDarkMode] = useState(false);
    const userDatas = useSelector((state) => state.users.users);
    const token = useSelector((state) => state.users.access_token);
    let href;

    const updateLangByusers = async (lang) => {

        const requestBody = {
            lang: lang,
            display_name: users.users?.display_name,
        };
        const res = await RequestFanbusy('creators/', 'PUT', requestBody, users.access_token);
        if (res.response.status_code === 200) {
            setState({ ...state, updateLangResponse: 'Langue mise a jour avec success' })
        }
    };

    React.useEffect(() => {
        i18n.changeLanguage(params.language);
        let maxAmountPost = convertCurrencyBalance(params.currency, params.currencies, 200, 'GET')
        let minAmountpost = convertCurrencyBalance(params.currency, params.currencies, 1, 'GET')
        setAmount({ ...amount, maxAmount: maxAmountPost, minAmount: minAmountpost });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.language, params.currencies, params.currency, users.users?._id]);

    useEffect(() => {
        localStorage.setItem("darkMode", JSON.stringify(isdarkMode));
        setIsDarkMode(darkMode);
        const handleScroll = () => {
            if (window.innerHeight) {
                setPopoverVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [darkMode, isdarkMode]);

    useEffect(() => {
        if (users?.users?.account_type !== 'CREATOR') {
            const getSelfCreator = async (creatorId) => {
                let res = await RequestFanbusy(`creators/${creatorId}`, 'GET', '', token);
                if (res?.status === 200) {
                    setAccountType(res.response?.data?.account_type);
                    let action = {
                        type: "LOGIN",
                        value: {
                            users: res.response?.data,
                            access_token: token
                        },
                    };
                    dispatch(action);
                }
            }
            getSelfCreator(users?.users?._id);
        }

    }, [token, dispatch, users?.users?._id, users?.users?.account_type, users.access_token]);

    useEffect(() => {
        const savedDarkMode = localStorage.getItem("darkMode");
        if (savedDarkMode) {
            setIsDarkMode(JSON.parse(savedDarkMode));
        }
    }, []);

    const handleClosePopup = () => {
        if (isPopoverVisible) {
            setPopoverVisible(false)
        }
    }

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);

        updateLangByusers(updateLanguage);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };

    const handleFileChange = async ({ target }) => {
        const { files } = target;
        const mediasCopy = [...medias];

        for (let file of files) {
            let mediaType = file.type.split('/')[0];

            let url;
            if (mediaType === 'image') {

                url = URL.createObjectURL(file);
            } else {
                url = URL.createObjectURL(file);
            }
            mediasCopy.push({ url, file });
            setState({ ...state, mediaType: mediaType });
        }
        setMedias(mediasCopy);
    }

    const handleDeleteFile = (index) => {
        const mediasCopy = [...medias].filter((_, i) => i !== index);
        setMedias(mediasCopy);
    }

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case 'postContent':
                setState({ ...state, postContent: value });
                break;
            case 'isFree':
                setState({ ...state, is_free: value });
                break;
            case 'commentDisabled':
                setState({ ...state, comment_disabled: value });
                break;
            case 'price':
                setState({ ...state, price_of_release: value });
                break;
            case 'asFee':
                setState({ ...state, asFee: value });
                break;
            case 'oFee':
                setState({ ...state, oFee: value });
                break;
            case 'collection':
                setState({ ...state, collectionId: value });
                break;
            default:
                break;
        }
    }

    const convertAmount = (amountConvert, type) => {
        let convert = convertCurrency(params.currency, params.currencies, amountConvert, type);
        return parseFloat(convert);
    }

    const checkPriceOfRelease = () => {
        if (!state.is_free === false && (isNaN(state.price_of_release) || state.price_of_release === '' || state.price_of_release === 0 || (state.price_of_release > parseFloat(amount.maxAmount.replace(/\s/g, '')) && state.price_of_release < parseFloat(amount.minAmount.replace(/\s/g, ''))))) {
            return true;
        } else {
            return false;
        }
    }

    const addPost = async () => {
        if (checkPriceOfRelease()) {
            setState({ ...state, releaseErrors: t('navigation.pleaseEnterAValidNumber') });
        } else {
            setState((prev) => ({ ...prev, isLoaded: true }));
            let files = [];
            let formData = new FormData();
            files = medias.map((media) => media.file);
            files.forEach((file) => formData.append('fileobject', file));
            let content = "";
            let linkNewPost = 'uploads/new/post?type=POST&content=' + content + '&comment_disabled=' + state.comment_disabled + '&currency=' + params.currency + '&post_type=' + state.postType + '&collection_id=' + state.collectionId + '&af_fee=' + convertAmount(state.price_of_release, 'POST') + '&as_fee=' + convertAmount(state.price_of_release, 'POST') + '&o_fee=' + convertAmount(state.price_of_release, 'POST') + '&price_of_release=' + convertAmount(state.price_of_release, 'POST')
            let newPost = await RequestFanbusy(linkNewPost, 'POST', formData, users.access_token);
            if (newPost.status === 201) {
                let post = newPost.response.data
                let data = {
                    content: state.postContent,
                    updated_at: post.updated_at
                }
                let res = await RequestFanbusy(`posts/${post._id}`, 'PUT', data, users.access_token);
                if (res.response.data.status_code !== 200) {
                    RequestFanbusy(`posts/${post._id}`, 'DELETE', users.access_token);
                }
                setState({ ...state, postContent: '', is_free: true, price_of_release: 0, asFee: 0, oFee: 0, comment_disabled: false, isLoaded: false });
                setMedias([]);
                if (linkRef.current) {
                    linkRef.current.click();
                }
                setIsModalOpen(false);
                window.location.href = '/'
                dispatch({ type: "REFRESH", value: 1 });
            }
            else if (newPost.status === 401) {
                setState({ ...state, releaseErrors: 'Your session has expired. Please log out and log back in to post.', isLoaded: false });
            }
        }
    }

    const logout = async () => {
        let res = await RequestFanbusy(`auth/logout`, 'GET', "", users.access_token);
        if (res.status === 200) {
            dispatch({ type: 'LOGOUT' });
            window.location.href = '/auth/login'
        }

    }

    const login = () => {
        window.location.href = '/auth/login'
    }

    const handleRefrechModal = () => {
        setMedias([]);
        setState({ ...state, postContent: '', is_free: false, price_of_release: 0, comment_disabled: false, isLoaded: false });
    }

    if (accountType === "CREATOR") {
        href = "/payment-details";
    } else if (accountType === "FANS") {
        href = "/become-creator";
    } else if (accountType === "REQUEST_CREATOR") {
        href = "/creator-request";
    }

    function setPostType(postTyp) {
        setState({ ...state, postType: postTyp })
    }

    const loadCollectionForPost = async (collection) => {
        setPostType(collection)
        let res = await RequestFanbusy("collection/self/creator", 'GET', '', users.access_token);
        // const pagination = res.response.paginage;
        if (res.status === 200) {
            setState((prev) => ({ ...prev, collections: res.response.data }));
            // setPagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));

        }
    }

    return (
        <>
            <aside onClick={() => handleClosePopup()} className={`col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12 pe-4 m-none top-0  `}>
                <div className="ps-0 m-none fix-sidebar" onClick={() => handleClosePopup()}>
                    <div className="sidebar-nav mb-3">
                        {token &&
                            <div className="pb-3 ms-3">
                                <a href={`/`} className="text-decoration-none">
                                    <header className="profile d-flex align-items-center">
                                        <Avatar user={userDatas} width={80} height={80} fontSize="fs-2" />
                                    </header>
                                </a>
                            </div>
                        }
                        <ul className={`navbar-nav justify-content-end flex-grow-1`}>
                            {!token &&
                                <li className="nav-item bg-transparent ms-1 ">
                                    <NavLink to={`/`} className={({ isActive }) => isActive ? `nav-link active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent  '}`} id="offcanvasNavbarDropdown" role="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" aria-expanded="true">
                                        <span style={{ fontWeight: '100px' }} className="me-3 fw-bold "> <RxAvatar size={28} /> </span>
                                    </NavLink>
                                </li>}
                            <li className="nav-item bg-transparent ms-1 ">
                                <NavLink
                                    to={`/`} className={({ isActive }) => isActive ? `nav-link active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent  '}`}>
                                    <span style={{ fontWeight: '100px' }} className="me-3 fw-bold "> <RiHome5Line size={28} /> </span>
                                    <span>{t('navigation.home')}</span>
                                </NavLink>
                            </li>
                            {<li style={{ marginRight: "30px" }} className="nav-item  ">

                            </li >}
                            {token && <>
                                <li className="nav-item ms-1">
                                    <NavLink to={`/messages`} className={({ isActive }) => isActive ? `nav-link active` : `nav-link`}>
                                        <span className=" me-3 "><MdOutlineChat size={27} /></span>
                                        {roomUnread > 0 &&
                                            <span style={{ marginTop: '5px', marginLeft: '29px', backgroundColor: "red", fontSize: '9px' }} className={`position-absolute  translate-middle badge gradient-button rounded-pill me-5 ${darkMode ? 'text-white' : 'text-white'} border border-2 border-white `}>
                                                {roomUnread}
                                            </span>
                                        }
                                        <span>{t('navigation.messages')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item ms-1">
                                    <NavLink to={`/bookmarks`} className={({ isActive }) => isActive ? `nav-link active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                        <span className=" me-3"><BiBookmarks size={26} /></span>
                                        <span>{t('navigation.bookmarks')}</span>
                                    </NavLink>
                                </li>
                                <>
                                    {/* <li className="nav-item ms-1">
                                        <NavLink to={`/collections`} className={({ isActive }) => isActive ? `nav-link active ` : `nav-link `}>
                                             //<span class="me-3 material-icons"> auto_stories </span> 
                                            <span className='me-3'><Svg name={'collection'} height='24px' width='24px' /></span>
                                            <span>{t('navigation.collections')}</span>
                                        </NavLink>
                                    </li> */}
                                </>

                            </>}
                            {
                                users?.users?.account_type === "CREATOR" &&
                                token && users.users?.account_type === "CREATOR" &&
                                <li className="nav-item ms-1">
                                    <NavLink to={`/fans`} className={({ isActive }) => isActive ? `nav-link active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                        <span className=" me-3"><MdOutlineDiversity2 size={25} /></span>
                                        <span>{t('navigation.fans')}</span>
                                    </NavLink>
                                </li>
                            }
                            {token && <> <li className="nav-item ms-1">
                                <NavLink to={`/follows`} className={({ isActive }) => isActive ? `nav-link active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                    <span className=" me-3"><MdOutlineDiversity1 size={25} /></span>
                                    <span>{t('navigation.subscriptions')}</span>
                                </NavLink>
                            </li>
                                <li className="nav-item ms-1">
                                    <NavLink to={`/profile`} className={({ isActive }) => (isActive && !window.location.href.includes('/profile/')) ? `nav-link active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                        <span className=" me-3"><CgProfile size={25} /></span>
                                        <span>{t('navigation.my_profile')}</span>
                                    </NavLink>
                                </li> </>}
                            <li className="nav-item dropdown bg-transparent ms-1 ">
                                <a href={`/`} className="nav-link " id="offcanvasNavbarDropdown" role="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" aria-expanded="true">
                                    <span className=" me-3"><CgMoreAlt size={25} /></span> {t('words.more')}
                                </a>
                            </li>
                        </ul >
                    </div >
                    {token && (
                        (users.users?.account_type === "CREATOR" || accountType === "CREATOR") ? (
                            <a href={`/`} className="btn gradient-button border-0 text-white w-75 text-decoration-none rounded-pill py-2 fw-bold text-uppercase m-0 gradient-button" data-bs-toggle="modal" data-bs-target="#postModal">
                                {t('navigation.new_post')}
                            </a>)
                            :
                            (<div className="position-relative">
                                <div className="input-group overflow-hidden  bg-white" onClick={() => setPopoverVisible(!isPopoverVisible)}>
                                    <button className="btn gradient-button text-white w-75 text-decoration-none rounded-pill py-2 fw-bold text-uppercase m-0 border-0">
                                        {t('navigation.new_post')}
                                    </button>
                                </div>
                                {isPopoverVisible && (
                                    <div style={{ zIndex: 1200 }} className="position-absolute bottom-50 ms-5 translate-middle-x mt-4 p-3 rounded-3 shadow-sm border bg-white">
                                        <p className="m-0 mb-2">{t('views.home.you_cannot_publish')}</p>
                                        <a href={`${users.users?.account_type === "REQUEST_CREATOR" ? '/creator-request' : '/become-creator'}`} className="btn gradient-button text-decoration-none text-white rounded-pill">{t('navigation.become_a_creator')}</a>
                                    </div>
                                )}
                            </div>)
                    )}
                </div >
            </aside >
            <div className="modal fade" id="languageModal" tabIndex="-1" aria-labelledby="ChangeLanguageModal" aria-hidden="true" style={{ zIndex: 2050 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className={`modal-content border-0 rounded-4 p-4 ${darkMode && 'bg-dark'}`}>
                        <div className={`  modal-header border-0 p-1 ${darkMode && "text-white"}`}>
                            <h6 className={` modal-title fw-bold fs-6 d-flex justify-content-center `} id="exampleModalLabel1">{t('navigation.choose_language')}</h6>
                            <button type="button" className={` ${darkMode && 'text-white'} fs-2 bg-transparent border-0`} data-bs-dismiss="modal" aria-label="Close"><span className="material-icons me-3 fb-color ">close</span></button>
                        </div>
                        <form>
                            <div className="modal-body pt-0 px-0" >
                                <div className="row py-3 gy-3 m-0">
                                    <div className="langauge-item col-6 px-1 mt-2 " onClick={() => changeLanguage('en')} data-bs-dismiss="modal" aria-label="Close">
                                        <input type="radio" className="btn-check" name="options-outlined" id="english2" defaultChecked={params.language === 'en' ? 'checked' : ''} />
                                        <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between" htmlFor="english2">
                                            <span className={`"text-start d-grid ${darkMode && 'text-white'}`}>
                                                <small className="ln-18">English</small>
                                                <small className="ln-18">Anglais</small>
                                            </span>
                                            <span className="material-icons text-muted md-20">check_circle</span>
                                        </label>
                                    </div>
                                    <div className="langauge-item col-6 px-1 mt-2 text-white" onClick={() => changeLanguage('fr')} data-bs-dismiss="modal" aria-label="Close">
                                        <input type="radio" className="btn-check" name="options-outlined" id="punjabi51f" defaultChecked={params.language === 'fr' ? 'checked' : ''} />
                                        <label className="btn btn-language btn-sm px-2 py-2 rounded-5 d-flex align-items-center justify-content-between mb-2" htmlFor="punjabi51f">
                                            <span className={`"text-start d-grid ${darkMode && 'text-white'}`}>
                                                <small className="ln-18">French</small>
                                                <small className="ln-18">Français</small>
                                            </span>
                                            <span className="material-icons text-muted md-20">check_circle</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="postModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="NewPostModal" aria-hidden="true" >
                <div className={`modal-dialog modal-dialog-centered ${isModalOpen ? 'show' : ''}`}>
                    <div className={`${darkMode ? 'bg-dark' : 'bg-white'} modal-content rounded-4 shadow-sm p-3 border-0`}>
                        <div className="modal-header d-flex align-items-center justify-content-start border-0 p-0 mb-3">
                            <div className="modal-title text-muted d-flex align-items-center" id="staticBackdropLabel">
                                <Avatar user={users.users} width={50} height={50} />
                                <div className="text-decoration-none d-flex flex-column text-captalize ms-2">
                                    <h6 className="fw-bold mb-0 text-body mt-0 text-capitalize d-flex align-items-center">
                                        {shortenName(users.users?.display_name, 15)}
                                        {users.users?.account_type === 'CREATOR' &&
                                            <span style={{ marginTop: '-4.7px' }} className="ms-1 p-0 md-16 fw-bold">{Images.verif} </span>
                                        }
                                    </h6>
                                    <small className="text-muted">@{shortenName(users.users?.pseudo, 10)}</small>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body p-0 mb-2" >
                            {medias.length > 0 &&
                                <div className="d-flex mb-3 pb-2" style={{ maxWidth: 470, overflowX: 'auto' }}>
                                    <div className="d-flex gap-2 flex-nowrap">
                                        {medias.map((media, index) => {
                                            let type = media.file.type.split('/')[0];

                                            if (type === 'image') {
                                                return (
                                                    <div key={index} className="position-relative rounded-5 overflow-hidden containHover">
                                                        <img src={media.url} alt="post_picture" style={{ width: 100, height: 100 }} />
                                                        <div className="position-absolute top-0 bottom-0 end-0 start-0 bg-black opacity-50 rounded-5 overlayBackground">
                                                            <button className="position-absolute bg-transparent text-white border-0 overlayIcon p-2 rounded-circle" name="deleteImg" onClick={() => handleDeleteFile(index)}>
                                                                <span className="material-icons">delete</span>
                                                            </button>
                                                        </div>

                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div key={index} className="position-relative rounded-5 overflow-hidden containHover">
                                                        <video controls style={{ width: 200, height: 100 }}>
                                                            <source src={media.url} type={media.file.type} />
                                                        </video>
                                                        <button className=" position-absolute bg-transparent text-white border-0 top-0 start-0 p-2 rounded-circle overlayDeleteMovie" name="deleteVideo" onClick={() => handleDeleteFile(index)}>
                                                            <span className="material-icons">delete</span>
                                                        </button>
                                                    </div>
                                                )
                                            }

                                        }
                                        )}
                                    </div>
                                </div>
                            }
                            <div className="form-floating">
                                <textarea className={`${darkMode ? 'bg-dark border-secondary text-white' : 'border'} form-control rounded-5 `} placeholder={t('form.new_post.whatnew_today') + ', ' + shortenName(users.users?.display_name, 15) + ' ?'} id="floatingTextarea2" style={{ height: '100px' }} name="postContent" value={state.postContent} onChange={textInputChange}></textarea>
                                {!(state.postContent.length > 0) &&
                                    <label htmlFor="floatingTextarea2" className="h6 text-muted mb-0">{t('form.new_post.addCaption')}...</label>
                                }
                            </div>
                        </div>
                        <div className={`${darkMode ? 'bg-dark  border-secondary' : 'bg-white modal-footer'} border justify-content-start px-1 py-1 rounded-5 mb-2`}>
                            <div className="rounded-4 m-0 px-3 py-2 d-flex align-items-center justify-content-between w-100 gap-2">
                                <span className="fw-bold fs-6">Photo/Video</span>
                                <label htmlFor="imageFile">
                                    <span className="material-icons " style={{ fontSize: 30 }}>add_photo_alternate</span>
                                    <input type="file" onChange={handleFileChange} id="imageFile" name="image" hidden accept=".jpg,.jpeg,.png,.mp4" multiple />
                                </label>
                            </div>
                        </div>
                        {state.postType === "COLLECTION" && state.collections.length > 0 &&
                            <select className={`form-select form-select-sm rounded-5 py-1 mb-2`} aria-label="form-select-sm example" name="collection" onChange={textInputChange}>
                                <option selected disabled bg-primary>{t('navigation.selectCollection')}</option>
                                {state.collections?.map((collection, index) => {
                                    return (
                                        <option className='text-black bg-white' key={index} value={collection.id}>
                                            {collection.titre}
                                        </option>
                                    )
                                })}

                            </select>

                        }
                        <div className="d-flex align-item-center justify-content-between gap-4 mb-2 px-2">
                            {state.postType === 'HOME' &&
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={state.is_free === true ? true : false} value="" id="flexCheckDefault" name="isFree" onChange={textInputChange} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        {state.is_free ? `${t('form.new_post.ppv')}` : `${t('form.new_post.ppv')}`}
                                    </label>
                                </div>
                            }
                            < div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" name="commentDisabled" onChange={textInputChange} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {state.comment_disabled ? t('form.new_post.comment_disabled') : t('form.new_post.disableComments')}
                                </label>
                            </div>

                        </div>
                        {!state.is_free === false &&
                            <div>
                                <input className="form-control form-control-sm w-100 mb-1" type="number" placeholder={`${t('form.new_post.price_of_release')} ${params.currency}`} name="price" value={state.price_of_release === 0 ? '' : state.price_of_release} onChange={textInputChange} />
                                {/* <input className="form-control form-control-sm w-100 mb-1" type="number" placeholder={`${t('form.new_post.asFee')} ${params.currency}`} name="asFee" value={state.asFee === 0 ? '' : state.asFee} onChange={textInputChange} />
                                <input className="form-control form-control-sm w-100" type="number" placeholder={`${t('form.new_post.oFee')} ${params.currency}`} name="oFee" value={state.oFee === 0 ? '' : state.oFee} onChange={textInputChange} /> */}
                                <p className="my-1" style={{ marginLeft: '8px' }}>Minimum {amount.minAmount} {params.currency}, Maximum {amount.maxAmount} {params.currency}</p>
                            </div>
                        }
                        <div className="mt-3">
                            <div className="d-flex align-items-center justify-content-end gap-3">
                                <button onClick={handleRefrechModal} className="btn btn-block text-secondary p-0 rounded-pill fw-bold px-4 py-1" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal" ref={linkRef}>{t('words.cancel')}</button>
                                <button disabled={state.isLoaded} onClick={() => addPost()} className={(medias.length === 0 || (!state.is_free === false && (state.price_of_release <= 0 || state.price_of_release > parseFloat(amount.maxAmount.replace(/\s/g, '')) || state.price_of_release < parseFloat(amount.minAmount.replace(/\s/g, ''))))) ? "btn btn-secondary btn-block rounded-pill fw-bold disabled px-4 py-1" : "btn gradient-button text-white btn-block rounded-pill fw-bold px-4 py-1"}>
                                    {state.isLoaded ?
                                        <Loader />
                                        :
                                        <div className="d-flex justify-content-center gap-1">
                                            <span className="material-icons">send</span>
                                            <span className="pb-0">{t('form.new_post.post')}</span>
                                        </div>
                                    }
                                </button>
                            </div>
                            {state.releaseErrors.length > 0 &&
                                <div className={`alert alert-${state.releaseErrors.includes('success') ? 'success' : 'danger'} alert-dismissible mb-0 mt-3`} role="alert">
                                    <div>{state.releaseErrors}</div>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div style={{ width: "240px", marginTop: "30px", marginLeft: "50px" }} className="modal-dialog d-flex justify-content-start" role="document">
                    <div className="modal-content py-2">
                        <div className="ps-0 m-none fix-sidebar">
                            <div className=" ">
                                <div className="py-2  d-flex justify-content-between">
                                    {token ?
                                        <a href={`/`} className="text-decoration-none">
                                            <header style={{ marginLeft: '7px' }} className="profile d-flex align-items-center ">
                                                <Avatar user={userDatas} width={60} height={60} fontSize="fs-5 " />
                                                <div className=''>
                                                    <h4 className={`${darkMode ? 'text-white' : 'text-dark'} mb-0`}>
                                                    </h4>
                                                </div>
                                            </header>
                                        </a>
                                        :
                                        <div style={{ textDecoration: "none" }} className="text-decoration-none">
                                            <header style={{ marginLeft: '7px' }} className="profile d-flex align-items-center ">
                                                <RxAvatar size={30} />
                                            </header>
                                        </div>
                                    }
                                    <button ref={refNavModal} type="button" className="close bg-transparent border-0 d-flex justify-content-end me-2" data-bs-dismiss="modal" aria-label="Close">
                                        <GrFormClose size={30} color='gray' />
                                    </button>
                                </div>
                                <ul className={`navbar-nav justify-content-end flex-grow-1`}>
                                    {token &&
                                        <li className=" bg-transparent  py-2">
                                            <button onClick={() => setIsShowProfile(!isShowProfile)}
                                                style={{ textDecoration: "none", backgroundColor: "transparent", border: "none", width: "100%", justifyContent: "space-between" }} className="d-flex align-items-center justify-content-between pe-3  ps-3">
                                                <span style={{ marginLeft: '-7.5px' }} className=" text-dark fw-bold fs-6 text-capitalize"> {shortenName(users.users?.display_name, 15)}</span>
                                                {isShowProfile ? <IoIosArrowUp size={18} /> : <IoIosArrowDown size={18} />}
                                            </button>
                                            {isShowProfile && <div className='py-2'>
                                                <div className='pb-2  ps-2'><label>@{userDatas.pseudo} </label> </div>
                                                {userDatas.account_type === 'CREATOR' && <span className='pe-5  ps-2 text-dark fw-bold'>{userDatas.fan_number}<label className='ps-1'> Fans </label>  </span>}
                                                <span className='text-dark fw-bold ps-2'> {userDatas.follow_number} <label className='ps-1 ' > Following </label> </span>
                                            </div>}
                                        </li>
                                    }
                                    {token && <>
                                        <li className="nav-item bg-transparent  ps-2 pb-3 pt-3   border-top">
                                            <a style={{ textDecoration: "none" }} href={`/profile`} className={({ isActive }) => isActive ? `active text-decoration-none ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent text-decoration-none  '} text-decoration-none`}>
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className="me-1 text-dark ">  <CgProfile size={22} /> </span>
                                                    <span className=' text-dark fw-bold ' >{t('navigation.my_profile')}</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item ps-2 pb-2 pt-2 ">
                                            <a href={`/bookmarks`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && ' bg-transparent text-white  '} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                <div className='d-flex align-items-center gap-2  '>
                                                    <span className=" me-1 text-dark "> <BiBookmarks size={22} /></span>
                                                    <span className=' text-dark fw-bold ' > {t('navigation.collections')}</span>
                                                </div>
                                            </a >
                                        </li >
                                        <li className="nav-item ps-2 pb-2 pt-2  border-bottom">
                                            <a href={`/settings`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className=" me-1 text-dark "> <FiSettings size={22} /> </span>
                                                    <span className=' text-dark fw-bold' > {t('navigation.settings')}</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item ps-2 pb-3 pt-3  ">
                                            <a href={`/account`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className=" me-1 text-dark "> <TfiCreditCard size={20} /></span>
                                                    <span style={{ marginLeft: -3 }} className=' text-dark fw-bold ps-1' >{t('navigation.my_wallet')}</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item ps-2 pb-2 pt-2  border-bottom">
                                            <a href={href} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className=" me-1 text-dark "> <BsCashStack size={20} /></span>
                                                    <span style={{ marginLeft: -3 }} className=' text-dark fw-bold ps-1' > {t('navigation.monetization')}</span>
                                                </div>
                                            </a>
                                        </li>

                                        <li className="nav-item ps-2 pb-2 pt-2  border-bottom">
                                            <a href={'/pay4me'} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className=" me-1 text-dark "> <CgGift size={24} /></span>
                                                    <span style={{ marginLeft: -3 }} className=' text-dark fw-bold ps-1'> {t('navigation.paidForMe')}</span>
                                                </div>
                                            </a>
                                        </li>

                                    </>}
                                    <li className={`nav-item ps-2 pb-3 pt-3 ${!token && 'border-top'}`}>
                                        <a href={`/help-and-support`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                            <div className='d-flex align-items-center gap-2 '>
                                                <span className=" me-1  text-dark "> <MdOutlineContactSupport size={24} /></span>
                                                <span className=' text-dark fw-bold' >{t('navigation.help_and_support')}</span>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="nav-item ps-2 pb-2 pt-2  border-bottom">
                                        <a onClick={() => CloseModals(refNavModal)} data-bs-toggle="modal" data-bs-target="#languageModal" href={`/`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                            <div className='d-flex align-items-center gap-2 '>
                                                <span className=" me-1 text-dark "> <MdLanguage size={22} /></span>
                                                <span className=' text-dark fw-bold' >{t('navigation.language')}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item ps-1 pb-3 pt-2">
                                        {token ?
                                            <button onClick={logout} style={{ textDecoration: "none", backgroundColor: "transparent" }} className="bg-transparent border-0">
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className=" me-1"> <RiLogoutBoxLine size={22} /></span>
                                                    <span className=' text-dark fw-bold' > {t('navigation.log_out')}</span>
                                                </div>
                                            </button>
                                            :
                                            <button onClick={login} style={{ textDecoration: "none", backgroundColor: "transparent" }} className="bg-transparent border-0">
                                                <div className='d-flex align-items-center gap-2 '>
                                                    <span className=" me-1"> <RiLoginBoxLine size={22} /></span>
                                                    <span className=' text-dark fw-bold' >{t('navigation.logIn')}</span>
                                                </div>
                                            </button>
                                        }
                                    </li>
                                </ul >
                            </div >
                        </div >
                    </div>
                </div>
            </div>

            <div className="modal fade" id="choiceModal" tabIndex="-1" aria-labelledby="choiceModal" aria-hidden="true" style={{ zIndex: 2050 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className={`modal-content border-0 rounded-4 pb-4 pt-2 gradient-button`}>
                        <button type="button" className={`fs-2 bg-transparent border-0 text-end `} data-bs-dismiss="modal" aria-label="Close"><span className="material-icons text-white me-0 ">close</span></button>
                        <div className={`modal-header border-0 p-1 w-100 d-flex align-items-center justify-content-center `}>
                            <h6 className={` modal-title fw-bold fs-6 text-white  `} id="exampleModalLabel1"> {t('navigation.whereWouldYouLikeToPublish')}</h6>
                        </div>

                        <div className='px-4'>
                            <div className="modal-body pt-0 px-0" >
                                <div className="py-3  m-0">
                                    <div className="langauge-item col-6 px-1 mt-2 w-100  " data-bs-dismiss="modal" aria-label="Close">
                                        <button onClick={() => setPostType("HOME")} data-bs-target='#postModal' data-bs-toggle='modal' className="btn btn-language text-white btn-sm px-2 py-2 rounded-pill w-100 text-center text-primary" >
                                            {t('navigation.publishToNewsFeed')}
                                        </button>
                                        {/* <button onClick={() => setPostType("DISCOVER")} data-bs-target='#postModal' data-bs-toggle='modal' className="btn btn-language btn-sm px-2 py-2 mt-2 rounded-pill w-100 text-white text-center">
                                             {t('navigation.publishToDiscover')} 
                                        </button > */}
                                        <button onClick={() => loadCollectionForPost("COLLECTION")} data-bs-target='#postModal' data-bs-toggle='modal' className="btn btn-language btn-sm px-2 py-2 mt-2 rounded-pill w-100 text-white text-center">
                                            {t('navigation.publishToSpecificCollection')}
                                        </button >

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export { NavigationBar }; 