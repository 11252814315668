
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';



import { RequestFanbusy } from '../Services';
import { convertCurrency, getStatutByUser, getReleasePost, getCreatorFollows, getSubscriptionFees } from '../Utils';
import DescriptionParagraph from './DescriptionParagraph';
import ShareModal from './ShareModal';
import TransactionsModal from './TransactionsModal';
import { LogOrSignToSubscribeView, PostBlocked, PostFooter, PostIllustrations, PostIllustrationsModal } from '../Views';
import { PostHeader } from '../Views';
import { PostComment, ReportModal } from '../Views/Posts';
import SubscriptionsPlansModal from './SubscriptionPlansModal';

const Post = ({ post, creatorId, bookmarks, likes, setFollows, released, follows, setListPostRelease, refrechAfterDeletePost, updatePostContent, setPeriod }) => {
    const postRef = useRef();
    const sref = useRef(null);
    const refCloseSubscriptionPlansModal = useRef();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const linkRef = React.useRef(null);
    const releaseRefs = React.useRef(null);
    const [isModalOpen, setIsModalOpen] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [showReleaseError, setShowReleaseError] = React.useState(true);
    const [isOnLine, setIsOnLine] = React.useState(false);
    const [alertType, setAlertType] = React.useState('');
    const [subscriptionFee, setSubscriptionFee] = React.useState(0);
    const [time, setTime] = React.useState();
    const [state, setState] = React.useState({
        comments: [],
        follows: [],
        phoneNumber: '',
        commentContent: '',
        tipAmount: '',
        tipMessage: '',
        mode: '',
        modeTip: '',
        modeRelease: '',
        cardId: '',
        reportContent: '',
        releaseErrors: '',
        currencyConvert: '',
        releaseConvert: '',
        haveFunds: true,
        isLoading: true,
        isLoaded: false,
        isLoading3Months: false,
        isLoading12Months: false,
        isLoading6Months: false

    });

    let fee = getSubscriptionFees(post.creator?.subscriptions_details, params.continent);

    const toggleComment = () => {
        setIsOpen(!isOpen);
    };

    const getCommentByPost = async () => {
        setIsOpen(true);
        let res = await RequestFanbusy(`comments/POST/${post._id}`, 'GET', '', users.access_token);
        if (res.status === 200) {
            setState((prev) => ({ ...prev, comments: res.response.data, isLoading: false }));
        }
    }

    const checkIfReleasePost = React.useCallback((post_id) => {
        if (users.users?._id === post.creator?._id) {
            return true
        } else {
            const myRelease = released.filter(post => post === post_id);
            if (post.is_free === true) {
                return true;
            }
            else if (myRelease.length > 0) {
                return true
            }

            return false;
        }
    }, [released, post.creator._id, post.is_free, users.users?._id])

    const checkIfSubscribeToCreator = React.useCallback((creator_id, post_id) => {
        if (users.users?._id === post.creator?._id) {
            return true;
        } else {
            const myRelease = released.filter(releasedPostId => releasedPostId === post_id);
            if (myRelease.length > 0) {
                return true;
            } else if (follows?.length > 0) {
                const followId = follows?.filter(follow => follow.fan?._id === creator_id || follow.creator?._id === creator_id);
                if (followId.length > 0) {
                    return true;
                }
            }
            return false;
        }
    }, [follows, released, post.creator?._id, users.users]);

    const disabled = (!checkIfReleasePost(post._id) || !checkIfSubscribeToCreator(creatorId)) ? 'pe-none' : '';

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'commentContent') {
            setState({ ...state, commentContent: value });
        }
        else if (name === 'tipAmount') {
            setState({ ...state, tipAmount: value });
        }
        else if (name === 'tipMessage') {
            setState({ ...state, tipMessage: value });
        }
        else if (name === 'reportContent') {
            setState({ ...state, reportContent: value });
        }
        else if (name === 'modePayment') {
            setState({ ...state, mode: value });
        }
        else if (name === 'modePaymentTip') {
            setState({ ...state, modeTip: value });
        }
        else if (name === 'modeRelease') {
            setState({ ...state, modeRelease: value });
        }
        else if (name === 'cardId') {
            setState({ ...state, cardId: value });
        }
        else if (name === 'phone_number') {
            setState({ ...state, phoneNumber: value });
        }
    }

    const releasePost = async (post_id) => {
        if (users.account.balance < post.price_of_release) {
            if (releaseRefs && releaseRefs.current) {
                releaseRefs.current.click();
            }
        }
        else {
            let form = {
                payment_mode: "ACCOUNT",
                continent: params.continent
            }
            setState((prev) => ({ ...prev, isLoaded: true }));
            let res = await RequestFanbusy(`posts/${post_id}/release-post`, 'POST', form, users.access_token);


            if (res.status === 201) {
                setState({ ...state, isLoaded: false, releaseErrors: res.response.description })
                setAlertType('success');
                dispatch({ type: "REFRESH", value: 1 });
                let releasePost = getReleasePost(users.access_token);
                releasePost.then((value) => {
                    setListPostRelease(value);
                });
            }
            else if (res.status === 400) {
                setState({ ...state, haveFunds: false, isLoaded: false });
            } else {
                setShowReleaseError(true)
                setState({ ...state, releaseErrors: 'Error, please try again.', isLoaded: false });
                setAlertType('warning');
            }
        }
    }

    const handleComment = async () => {
        setState({ ...state, isLoaded: true });
        const form = {
            referral_id: post._id,
            content: state.commentContent
        };
        let res = await RequestFanbusy('comments/post', 'POST', form, users.access_token);

        if (res.status === 201) {
            setState((prev) => ({ ...prev, commentContent: '' }));
            getCommentByPost();
            dispatch({ type: "REFRESH", value: 1 });
        }
        setState({ ...state, isLoaded: false, commentContent: '' });
    }

    const handleDeleteComment = async (commentId) => {
        let res = await RequestFanbusy(`comments/${commentId}`, 'DELETE', '', users.access_token);

        let comments = []
        if (res.status === 200) {
            comments = state.comments.filter((comment) => comment._id !== commentId)
            setState((prev) => ({ ...prev, comments: comments }));
            dispatch({ type: "REFRESH", value: 1 });
        }
    }

    const handleReportPost = async () => {
        setState((prev) => ({ ...prev, isLoaded: true }));
        let form = {
            comment: state.reportContent,
            post_id: post._id
        }
        let res = await RequestFanbusy('reports/', 'POST', form, users.access_token);
        if (res.status === 201) {
            setState({ ...state, reportContent: '', isLoaded: false });
            dispatch({ type: "REFRESH", value: 1 });
            if (linkRef.current) {
                linkRef.current.click();
            }
            setIsModalOpen(false);
        } else {
            setShowReleaseError(true)
            setState({ ...state, releaseErrors: 'Error, please try again.', isLoaded: false });
            setAlertType('warning');
        }
    }

    const subscribeToCreator = async (subscriptionPeriod) => {
        setPeriod(subscriptionPeriod)
        setTime(subscriptionPeriod)
        const form = {
            creator_id: post.creator._id,
        };

        const subscriptionFees = {
            1: fee.monthly,
            3: fee.quarterly,
            6: fee.halfYearly,
            12: fee.yearly
        };

        if (!checkIfSubscribeToCreator(creatorId)) {
            const requiredFee = subscriptionFees[subscriptionPeriod];
            setSubscriptionFee(requiredFee)

            if (users.account.balance < requiredFee) {
                if (releaseRefs && releaseRefs.current) {
                    releaseRefs.current.click();
                }
                refCloseSubscriptionPlansModal && refCloseSubscriptionPlansModal.current.click()
            } else {
                setState((prev) => ({
                    ...prev,
                    isLoaded: subscriptionPeriod === 1 ? true : prev.isLoaded,
                    isLoading3Months: subscriptionPeriod === 3 ? true : prev.isLoading3Months,
                    isLoading6Months: subscriptionPeriod === 6 ? true : prev.isLoading6Months,
                    isLoading12Months: subscriptionPeriod === 12 ? true : prev.isLoading12Months
                }));
                let res = await RequestFanbusy(`souscriptions/?continent=${params.continent}&subscription_period=${subscriptionPeriod}`, 'POST', form, users.access_token);

                if (res.status === 201) {
                    let follows = getCreatorFollows(users.access_token);
                    follows.then((value) => {
                        setFollows(value);
                    });
                    setState((prev) => ({
                        ...prev,
                        isLoaded: false,
                        isLoading3Months: false,
                        isLoading6Months: false,
                        isLoading12Months: false
                    }));
                    refCloseSubscriptionPlansModal && refCloseSubscriptionPlansModal.current.click()
                    dispatch({ type: "REFRESH", value: 1 });
                } else if (res.status === 500) {
                    setShowReleaseError(true);
                    setState({
                        ...state,
                        releaseErrors: 'Error, please try again.',
                        isLoaded: false,
                        isLoading3Months: false,
                        isLoading6Months: false,
                        isLoading12Months: false
                    });
                    setAlertType('warning');
                }
            }
        }
    };

    const handleDataReady = (data) => {
        setShowReleaseError(true)
        setState((prev) => ({ ...prev, releaseErrors: data }));
    };

    const handleRefrechModal = () => {
        setShowReleaseError(true)
        setState({ ...state, reportContent: '', releaseErrors: '', isLoaded: false });
        setAlertType('success')
    }

    React.useEffect(() => {
        function videoScroll() {
            let windowHeight = window.innerHeight;
            let videoEls = document.querySelectorAll(`#illustrationPostCarousel-${post._id} video`);

            videoEls.forEach(videoEl => {
                let videoHeight = videoEl.clientHeight;
                let videoClientRect = videoEl.getBoundingClientRect().top;

                if (videoClientRect <= ((windowHeight) - (videoHeight * .5)) && videoClientRect >= (0 - (videoHeight * .5))) {
                    if (videoEl.paused) {
                        videoEl.play().catch(error => {
                            console.error('Error attempting to play video:', error);
                        });
                    }
                } else {
                    if (!videoEl.paused) {
                        videoEl.pause();
                    }
                }
            });
        }

        window.addEventListener('load', videoScroll);
        window.addEventListener('scroll', videoScroll);

        return () => {
            window.removeEventListener('load', videoScroll);
            window.removeEventListener('scroll', videoScroll);
        };
    }, [post._id]);



    React.useEffect(() => {
        if (users.users?._id !== post.creator?._id) {
            let online = getStatutByUser(post.creator?._id);
            online.then((value) => {
                setIsOnLine(value)
            })
        }

    }, [post.creator?._id, users.users?._id]);


    React.useEffect(() => {
        checkIfSubscribeToCreator(creatorId)
        checkIfReleasePost(post._id)

        if (state.releaseErrors?.length > 0) {
            const timer = setInterval(() => {
                setState((prev) => ({ ...prev, releaseErrors: '' }));
            }, 10000);

            return () => clearInterval(timer);
        }
    }, [checkIfReleasePost, checkIfSubscribeToCreator, creatorId, post._id, state.releaseErrors?.length]);

    const convertAmount = (amount) => {
        let convert = convertCurrency(params.currency, params.currencies, amount, 'GET');
        return convert;
    }

    return (
        <>
            <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'}  pt-1 pb-2 feed-item  mb-1 border-bottom `} ref={postRef} onContextMenu={(e) => e.preventDefault()}>
                <div>
                    <div className="d-flex  align-items-start w-100">
                        <div className="w-100">
                            <PostHeader
                                post={post}
                                isOnLine={isOnLine}
                                refrechAfterDeletePost={refrechAfterDeletePost}
                                setShowReleaseError={setShowReleaseError}
                                showReleaseError={showReleaseError}
                                updatePostContent={updatePostContent}
                            />
                            <div className="my-2">
                                <div className="ps-2 text-dark pb-1">
                                    <DescriptionParagraph
                                        bio={post.content}
                                        paragraphRef={post._id}
                                        checkIfReleasePost={checkIfReleasePost(post._id)}
                                        checkIfSubscribeToCreator={checkIfSubscribeToCreator(creatorId)}
                                        profile={false}
                                    />

                                </div>
                                {!checkIfReleasePost(post._id) || !checkIfSubscribeToCreator(creatorId, post._id) ?
                                    <PostBlocked
                                        post={post}
                                        checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                        creatorId={creatorId}
                                        isLoaded={state.isLoaded}
                                        releasePost={releasePost}
                                        subscribeToCreator={subscribeToCreator}
                                        haveFunds={state.haveFunds}
                                        releaseErrors={state.releaseErrors}
                                        showReleaseError={showReleaseError}
                                        setShowReleaseError={setShowReleaseError}
                                        releaseRefs={releaseRefs}
                                        alertType={alertType}
                                        fee={fee}
                                    />
                                    :
                                    <PostIllustrations post={post} />

                                }
                                <PostFooter
                                    isOpen={isOpen}
                                    disabled={disabled}
                                    toggleComment={toggleComment}
                                    checkIfReleasePost={checkIfReleasePost}
                                    post={post}
                                    likes={likes}
                                    checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                    getCommentByPost={getCommentByPost}
                                    creatorId={creatorId}
                                    bookmarks={bookmarks}
                                />

                                {isOpen &&
                                    <PostComment
                                        post={post}
                                        checkIfReleasePost={checkIfReleasePost}
                                        checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                        creatorId={creatorId}
                                        textInputChange={textInputChange}
                                        commentContent={state.commentContent}
                                        isLoading={state.isLoading}
                                        comments={state.comments}
                                        isLoaded={state.isLoaded}
                                        handleDeleteComment={handleDeleteComment}
                                        handleComment={handleComment}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PostIllustrationsModal post={post} />

            <ReportModal post={post}
                isModalOpen={isModalOpen}
                isOnLine={isOnLine}
                handleRefrechModal={handleRefrechModal}
                isLoaded={state.isLoaded}
                releaseErrors={state.releaseErrors}
                setShowReleaseError={setShowReleaseError}
                showReleaseError={showReleaseError}
                reportContent={state.reportContent}
                handleReportPost={handleReportPost}
                textInputChange={textInputChange}
                linkRef={linkRef}
            />
            <ShareModal id={post._id} postId={true} share={t("views.post.sharePost")} />
            {(!users.authenticated && users.access_token === "") &&
                <LogOrSignToSubscribeView id={post.creator._id} creator={post.creator} />
            }
            {users.users &&
                <TransactionsModal
                    onDataReady={handleDataReady}
                    data={{ id: post.creator._id, photo: post.creator.photo, account_type: post.creator.account_type, display_name: post.creator.display_name, pseudo: post.creator.pseudo, postId: post._id, post: true }}
                    action={!checkIfSubscribeToCreator(creatorId) ? 'subscribeCreator' : (!checkIfReleasePost(post._id) ? 'releasePost' : 'leaveTip')}
                    nameForm={!checkIfSubscribeToCreator(creatorId) ? `${t('views.post.subscribe')}` : (!checkIfReleasePost(post._id) ? `${t('views.post.unlockPost')}` : `${t('navigation.leaveTip')}`)}
                    amount={!checkIfSubscribeToCreator(creatorId) ? convertCurrency(params.currency, params.currencies, subscriptionFee, 'GET')?.replace(/\s/g, '') : (!checkIfReleasePost(post._id) ? convertCurrency(params.currency, params.currencies, post?.price_of_release, 'GET')?.replace(/\s/g, '') : 0)}
                    setListPostRelease={setListPostRelease}
                    period={time}
                />

                //<CheckMailModalView />
            }
            <SubscriptionsPlansModal
                checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                isLoaded={state.isLoaded}
                creator={post.creator}
                subscribeToCreator={subscribeToCreator}
                t={t}
                pseudo={post.creator.pseudo}
                amountForMonth={convertAmount(fee?.monthly)}
                amountForTreMonth={convertAmount(fee?.quarterly)}
                amountForSixMonth={convertAmount(fee?.halfYearly)}
                amountForTwelvfMonth={convertAmount(fee?.yearly)}
                releaseRefs={releaseRefs}
                isLoading3Months={state.isLoading3Months}
                isLoading6Months={state.isLoading6Months}
                isLoading12Months={state.isLoading12Months}
                refCloseSubscriptionPlansModal={refCloseSubscriptionPlansModal}
                sref={sref}
            />
        </>
    )
}

export default Post;