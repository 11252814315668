import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";

import { Avatar, Loader, NoContent } from "../../Components";
import { RequestFanbusy } from "../../Services";
import { Images } from "../../Constants";
import { formatDateRelativePost, getRoomAndAllStatusReceiverByUser, shortenName } from "../../Utils";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Room = ({ onSelectReceiver, dataSocket }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const users = useSelector(state => state.users);
    const [state, setState] = useState({
        isLoading: true,
        tchats: [],
        creatorData: [],
        statusUsers: {},
        notification: {},
        selectedTchatId: null,
    });

    useEffect(() => {
        if (dataSocket && dataSocket.type === 'statut') {
            setState((prevState) => ({
                ...prevState,
                statusUsers: {
                    ...prevState.statusUsers, [dataSocket.user_id]: dataSocket.online ? 'En ligne' : 'Hors ligne',
                },
            }));
        }
        if (dataSocket && dataSocket.type === 'notification') {
            let newDataSocketContent = dataSocket.content;
            let roomIdToUpdate = dataSocket.room_id;
            let last_message_date = dataSocket.last_message_date
            setState((prevState) => ({
                ...prevState,
                tchats: prevState.tchats.map((chat) => {
                    if (chat.room_id === roomIdToUpdate) {
                        return {
                            ...chat,
                            last_message_content: newDataSocketContent,
                            last_message_date: last_message_date
                        };
                    }
                    return chat;
                }),
                notification: {
                    ...prevState.notification,
                    [dataSocket.room_id]: dataSocket.unread_count,
                },
            }));

            let stat = getRoomAndAllStatusReceiverByUser(users.users._id);
            stat.then(async (value) => {
                const updatedDataChat = [];
                let updatedDataChats = [];
                if (value.length > 0) {
                    await Promise.all(
                        value.map(async (chat) => {
                            try {
                                const creatorResponse = await RequestFanbusy(`creators/${chat.receiver}`, 'GET', '', users.access_token);

                                if (creatorResponse.status === 200) {
                                    const updatedChat = {
                                        ...chat,
                                        receiver: creatorResponse.response.data,
                                    };
                                    updatedDataChat.push(updatedChat);
                                    updatedDataChat.sort((a, b) => {
                                        const dateA = new Date(a.last_message_date);
                                        const dateB = new Date(b.last_message_date);
                                        return dateB - dateA;
                                    });
                                    updatedDataChats = updatedDataChat.filter(tchat => tchat.last_message_content !== null)
                                }
                            } catch (error) {
                                console.error('Une erreur s\'est produite lors de la récupération des données du créateur', error);
                            }
                        })
                    );
                }

                setState((prevState) => ({ ...prevState, tchats: [...updatedDataChats] }));
            });

        }

    }, [dataSocket, users.access_token, users.users?._id]);

    useEffect(() => {
        let stat = getRoomAndAllStatusReceiverByUser(users.users?._id);
        stat.then(async (value) => {
            const statusUsersObject = {};
            if (value.length > 0) {
                value.forEach((status) => {
                    statusUsersObject[status.statut.user_id] = status.statut.online ? 'En ligne' : 'Hors ligne';
                });
            }

            const notificationObject = {};
            if (value.length > 0) {
                value.forEach((item) => {
                    notificationObject[item.room_id] = item.notification
                });
            }

            const updatedDataChat = [];
            let updatedDataChats = [];
            if (value.length > 0) {
                await Promise.all(
                    value.map(async (chat) => {
                        try {
                            const creatorResponse = await RequestFanbusy(`creators/${chat.receiver}`, 'GET', '', users.access_token);

                            if (creatorResponse.status === 200) {
                                const updatedChat = {
                                    ...chat,
                                    receiver: creatorResponse.response.data,
                                };
                                updatedDataChat.push(updatedChat);
                                updatedDataChat.sort((a, b) => {
                                    const dateA = new Date(a.last_message_date);
                                    const dateB = new Date(b.last_message_date);
                                    return dateB - dateA;
                                });
                                updatedDataChats = updatedDataChat.filter(tchat => tchat.last_message_content !== null)

                            } else {
                                console.error('Erreur lors de la récupération des données du créateur');
                            }
                        } catch (error) {
                            console.error('Une erreur s\'est produite lors de la récupération des données du créateur', error);
                        }
                    })
                );
            }

            setState((prevState) => ({ ...prevState, tchats: updatedDataChats, statusUsers: statusUsersObject, notification: notificationObject, isLoading: false }));
        });
    }, [users.access_token, users.users?._id]);

    const updateNotificationToOne = (tchatId) => {
        setState((prevState) => ({
            ...prevState,
            notification: {
                ...prevState.notification,
                [tchatId]: 0,
            },
            selectedTchatId: prevState.selectedTchatId === tchatId ? null : tchatId,

        }));
    };

    const globalfunction = async (room, receiverObjet) => {
        onSelectReceiver(room, receiverObjet, state.statusUsers[receiverObjet._id]);
        updateNotificationToOne(room);
        navigate(`my/chat/${room}/${receiverObjet._id}`)
    }

    return (
        <div className="main-content">
            <div className='d-flex border-bottom justify-content-between bg-white fix-sidebar top-0 shadow-sm ' style={{ padding: '19px', height: '60px' }}>
                <div className="d-flex align-items-center py-1">
                    <div className='rounded-circle bouton-survol px-1'>
                        <a href={`/`} className="material-icons text-dark text-decoration-none mt-1">arrow_back</a>
                    </div>
                    <div className="align-items-center w-100 ms-2 mb-0 fw-bold text-body fs-6 ">
                        {t('views.messages.messages')}
                    </div>
                </div>

                <div className='rounded-circle bouton-survol ' style={{ height: '30px' }}>
                    <a href='/messages/creat' className='bg-transparent text-black material-icons border-0 text-center justify-content-end text-decoration-none'>add</a>
                </div>
            </div>
            <div className={` size-contener-room `} style={{ overflowY: 'scroll' }} >
                {(state.isLoading) ?
                    <div style={{ marginTop: '50px' }}>
                        <Loader styleColor={true} />
                    </div>
                    :
                    <>
                        {state.tchats.length === 0 ?
                            <div className="sticky-sidebar2 mb-3 me-1">
                                <div class="py-5 text-center">
                                    <div class="text-center pb-5 mx-5">
                                        <NoContent message={`${t('views.messages.youHaveNoChat')}`} transaction={true} />
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className='web-none '>
                                    {
                                        state.tchats.map(tchat => {
                                            const isSelected = state.selectedTchatId === tchat.room_id;
                                            return (
                                                <>
                                                    <div className=' bouton-survol'>
                                                        <div key={tchat.room_id} onClick={() => { globalfunction(tchat.room_id, tchat.receiver, state.statusUsers[tchat.receiver._id]) }} className={`p-3 d-flex text-dark text-decoration-none account-item bg-white  custom-border-style ${isSelected ? 'bgmsg' : ''} `}>
                                                            <a href={tchat.receiver.account_type === 'CREATOR' && `/${tchat.receiver.pseudo}`} className="position-relative mx-2 text-decoration-none">
                                                                <Avatar user={tchat?.receiver} width={50} height={50} fontSize="fs-6" />
                                                                {(state.statusUsers[tchat.receiver?._id] === 'En ligne') &&
                                                                    <span className="position-absolute translate-middle p-1 border border-green rounded-circle" style={{ top: '39px', left: '85%', backgroundColor: '#35D063' }}>
                                                                        <span className="visually-hidden ">New alerts</span>
                                                                    </span>
                                                                }
                                                            </a>
                                                            <div className='ms-3'>
                                                                <p className="fw-bold mb-0 pe-1 d-flex align-items-center fs-6" >
                                                                    <span> {shortenName(tchat.receiver?.display_name, 10)}</span>
                                                                    {tchat.receiver?.account_type === 'CREATOR' &&
                                                                        <span className="ms-0 material-icons  fw-bold text-secondary  rounded-circle ov-icon md-0 mb-1">{Images.verif}</span>
                                                                    }
                                                                </p>
                                                                <div className="text-muted fw-light d-flex justify-content-between " >
                                                                    <span className="fw-bold text-muted d-flex align-items-center small">
                                                                        {tchat.last_message_content !== null ? (tchat.last_message_content === 'image-content-only' ? <span className="material-icons md-20 me-2">image</span> : <>{shortenName(tchat.last_message_content, 10)}</>) : ''}
                                                                    </span>
                                                                    <small className="position-absolute translate-middle text-muted align-items-center me-2 " style={{ top: '50px', left: '80%' }}>
                                                                        {formatDateRelativePost(tchat.last_message_date, t)}
                                                                    </small>
                                                                    {
                                                                        state.notification[tchat.room_id] !== 0 &&
                                                                        <span className="position-absolute translate-middle p-1 bg-primary border border-light rounded-circle me-0 " style={{ top: '49px', left: '90%' }} >
                                                                            <span className="visually-hidden text-primary ">pointer</span>
                                                                        </span>

                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <div className='m-none '>
                                    <div className="bg-transparent overflow-hidden mb-4 mb-lg-0 " style={{ overflowY: 'scroll' }}>
                                        {
                                            state.tchats.map(tchat => {
                                                const isSelected = state.selectedTchatId === tchat.room_id;
                                                return (
                                                    <div className='bouton-survol'>
                                                        <div key={tchat.room_id} onClick={() => { globalfunction(tchat.room_id, tchat.receiver, state.statusUsers[tchat.receiver._id]) }} className={`p-2 d-flex text-dark text-decoration-none account-item custom-border-style w-100 ${isSelected ? ' bgmsg ' : ''}`}>
                                                            <a href={tchat.receiver.account_type === 'CREATOR' && `/${tchat.receiver.pseudo}`} className="position-relative text-decoration-none">
                                                                <Avatar user={tchat?.receiver} width={50} height={50} fontSize="fs-6" />
                                                                {(state.statusUsers[tchat.receiver?._id] === 'En ligne') &&
                                                                    <span className="position-absolute translate-middle p-1 border border-light rounded-circle me-0" style={{ top: '39px', left: '85%', backgroundColor: '#35D063' }}>
                                                                        <span className="visually-hidden ">New alerts</span>
                                                                    </span>
                                                                }
                                                            </a>
                                                            <div className='ms-3' >
                                                                <p className="fw-bold mb-0 pe-1 fs-6 d-flex align-items-center  " >
                                                                    <span>{shortenName(tchat.receiver?.display_name, 11)}</span>
                                                                    {tchat.receiver?.account_type === 'CREATOR' &&
                                                                        <span className="ms-0 material-icons  fw-bold text-secondary  rounded-circle ov-icon md-0 ">{Images.verif}</span>
                                                                    }
                                                                </p>
                                                                <div className="fw-light d-flex flex-wrap">
                                                                    <span className="text-muted align-items-center small  me-5" >
                                                                        {tchat.last_message_content !== null ? (tchat.last_message_content === 'image-content-only' ? <span className="material-icons md-13 me-2">image</span> : <>{shortenName(tchat.last_message_content, 20)}</>) : ''}
                                                                    </span>
                                                                    <small className="position-absolute translate-middle text-muted align-items-center me-2 " style={{ top: '42.5px', left: '80%' }}>
                                                                        {formatDateRelativePost(tchat.last_message_date, t)}
                                                                    </small>
                                                                    {
                                                                        state.notification[tchat.room_id] !== 0 &&
                                                                        <span className="position-absolute translate-middle p-1 bg-primary border border-light rounded-circle me-0 " style={{ top: '41px', left: '91%' }}>
                                                                            <span className="visually-hidden text-primary ">pointer</span>
                                                                        </span>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}
export default Room