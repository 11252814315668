import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertCurrency, getStatutByUser } from "../Utils";
import Avatar from "./Avatar";
import { Images } from "../Constants";
import { RequestFanbusy } from "../Services";
import Loader from "./Loader";
import { shortenName } from "../Utils";

const SuggestionCreator = ({ data, suggestionRight, getAllCreator, creatorLength, styleResp }) => {
    const { t } = useTranslation();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const params = useSelector((state) => state.params);
    const users = useSelector((state) => state.users);
    const subscriptionRef = React.useRef(null);
    const [name] = React.useState(data.display_name || '');
    const [pseudo] = React.useState(data?.pseudo);
    const [bio] = React.useState(data?.bio)
    const [convert, setConvert] = React.useState()
    const [state, setState] = React.useState({
        releaseErrors: '',
        follows: [],
        isLoading: false
    });
    const [isOnLine, setIsOnLine] = React.useState(false);

    let displayName = name;
    let pseudoName = pseudo;

    if (suggestionRight === true) {
        if (name.length >= 15) {
            displayName = name.substring(0, 15) + "...";
        }
        if (pseudo?.length >= 15) {
            pseudoName = pseudo.substring(0, 15) + "...";
        }
        if (styleResp) {
            displayName = shortenName(name, 24)
            pseudoName = shortenName(pseudo, 24)
        }
    } else {
        if (name.length >= 15) {
            displayName = name.substring(0, 12) + "...";
        }
        if (pseudo.length >= 15) {
            pseudoName = pseudo.substring(0, 12) + "...";
        }
    }

    React.useEffect(() => {
        let online = getStatutByUser(data._id);
        online.then((value) => {
            setIsOnLine(value)
        });

    }, [data._id]);

    React.useEffect(() => {
        if (data) {
            let convert = convertCurrency(params.currency, params.currencies, data.subscription_fee, 'GET');
            setConvert(convert);
        }
    }, [data, params.currencies, params.currency]);


    const checkBalanceCreator = () => {
        if (users.account.balance >= data?.subscription_fee) {
            return true
        }
        return false
    }

    const subscribeToCreator = async () => {
        const form = {
            creator_id: data._id,
        };
        if (!checkBalanceCreator()) {
            if (subscriptionRef) {
                subscriptionRef.current.click();
            }
        }
        else {
            setState((prev) => ({ ...prev, isLoading: true }));
            let res = await RequestFanbusy('creators/', 'POST', form, users.access_token);
            if (res.status === 201) {
                getAllCreator()
            }
            else if (res.status === 400 && res.response.response_type === "Insuffisant fund") {
                setState({ ...state, releaseErrors: t('errors_messages.insuffisant_fund'), isLoading: false });
            }
            else if (res.status === 400 && res.response.response_type === "Bad Request") {
                setState({ ...state, releaseErrors: res.response.description, isLoading: false });
            }
            else {
                setState({ ...state, releaseErrors: 'An error has occurred please try again.', isLoading: false });
            }
        }
    };



    return (
        <>
            {suggestionRight ?
                <div className={`${darkMode ? 'bg-dark border-0' : 'bg-white'}  rounded-3 mx-2 ${styleResp && (creatorLength?.index % 2 !== 0) ? '' : 'mt-2'} border shadow-sm profile position-relative overflow-hidden`} style={{ position: 'relative', marginBottom: (styleResp && (creatorLength?.index % 2 !== 0 && (creatorLength?.length > 2 || creatorLength?.length !== 2))) ? '30px' : '10px' }}>
                    <div className="position-relative" style={{ backgroundImage: `url(${data.banner?.length !== 0 ? data.banner : Images.background})`, backgroundSize: 'cover', height: '100px', }}>
                        <span className="position-absolute px-2 py-0 mt-1 me-1 text-light rounded-3 top-0 end-0 fw-bold" style={{ backgroundColor: 'rgba(0, 0, 0, .5)', fontSize: '0.8em' }}>
                            {data.is_free_account ? (
                                `${t('words.free')}`
                            ) : (
                                `${convert} ${params.currency}/${t('words.month')}`
                            )}
                        </span>
                        <div className="position-absolute bottom-0 end-0 start-0" style={{ backgroundColor: 'rgba(0, 0, 0, .3)', height: '50%' }}></div>
                    </div>
                    <NavLink to={data.account_type === 'CREATOR' && (users.users._id !== data._id ? `/${data.pseudo}` : '/profile')} className="d-flex align-items-center text-decoration-none position-relative ps-3 pe-2 py-0">
                        <div className="text-decoration-none position-relative" style={{ marginTop: -100 }}>
                            <Avatar user={data} width={60} height={60} fontSize="fs-4" />
                            {isOnLine && <span className="rounded-circle position-absolute bottom-0 mb-2" style={{ width: 10, height: 10, right: '2px', background: '#35D063', border: '2px solid white' }}></span>}
                        </div>
                        <div className="ms-2 " style={{ marginTop: -50 }}>
                            <div className="d-flex align-items-center">
                                <h6 className="mb-0 d-flex align-items-start text-light fs-6 fw-bold text-capitalize">{displayName}
                                </h6>
                                {data.account_type === 'CREATOR' &&
                                    <span className="me-2 md-16 fw-bold text-white ms-1 mb-1">{Images.verifWhite} </span>
                                }
                            </div>
                            <p className="text-white mb-0">@{pseudoName}</p>
                        </div>
                    </NavLink>
                </div>
                :
                <div className="rounded-4 overflow-hidden shadow-sm profile bg-white mt-2" style={{ position: 'relative', width: 260 }}>
                    <div style={{ backgroundImage: `url(${data.banner.length > 0 ? data.banner : Images.background})`, height: '85px', backgroundSize: 'cover' }}>
                        <div style={{ backgroundColor: 'rgba(0, 0, 0, .5)' }}></div>
                    </div>
                    <div className="align-items-center px-2 pt-1">
                        <NavLink to={`/${data.pseudo}`} className="d-flex text-decoration-none">
                            <div className="ms-2 pt-4" style={{ marginTop: -60, position: 'relative' }}>
                                <Avatar user={data} width={80} height={80} fontSize="fs-6" />
                                {isOnLine &&
                                    <span className="rounded-circle position-absolute bottom-0" style={{ width: 14, marginBottom: 15, height: 14, right: '-1px', background: '#35D063', border: '2px solid white' }}></span>
                                }
                            </div>
                            <div className=" ms-2">
                                <h6 className="mb-0 d-flex align-items-center text-body  fw-bold text-capitalize m-none" style={{ fontSize: '1.2em' }}>
                                    <span className="m-0 p-0"> {displayName}</span>
                                    {data.account_type === 'CREATOR' &&
                                        <span className="me-2 ms-1 p-0 md-16 fw-bold text-white "> {Images.verif} </span>
                                    }
                                </h6>
                                <p className="text-muted mb-0 m-none" style={{ fontSize: '1em' }}>@{pseudoName}</p>

                                <h6 className="mb-0 d-flex align-items-center text-body  fw-bold text-capitalize web-none" title={displayName} style={{ fontSize: '1.1em' }}>
                                    <span className="m-0 p-0"> {displayName}</span>
                                    {data.account_type === 'CREATOR' &&
                                        <span className="me-1 p-0 md-16 fw-bold  web-none ms-1 mb-1 ">{Images.verifWhite}</span>
                                    }
                                </h6>
                                <p className="text-muted mb-0 web-none" style={{ fontSize: '1em' }}>@{pseudoName}</p>


                            </div>
                        </NavLink>
                        <p className="text-black mb-0 ms-2 pt-1" style={{ fontSize: '1em', height: 43 }}>{shortenName(bio, 55)}</p>

                    </div>
                    <div className="p-2">
                        <div className="ms-auto btn-group w-100" tabIndex="-1" role="button" aria-disabled="true">
                            <button onClick={subscribeToCreator} className="btn fb-btn-color text-white d-flex align-items-center justify-content-between btn-sm px-2 rounded-pill" htmlFor="btncheck1" style={{ fontSize: '1em' }}>
                                {state.isLoading ?
                                    <Loader /> :
                                    <>
                                        <span className="fw-bold">{t('words.subscribe')}</span>
                                        <span className="fw-bold">
                                            {data.is_free_account ? (
                                                `${t('words.free')}`
                                            ) : (
                                                <>
                                                    {!checkBalanceCreator() &&
                                                        <a href={`/${data.pseudo}`} className="text-decoration-none" ref={subscriptionRef}>.</a>
                                                    }
                                                    {convert} {params.currency}/{t('words.month')}
                                                </>
                                            )}
                                        </span>
                                    </>
                                }
                            </button>
                        </div>
                        {state.releaseErrors.length > 0 &&
                            <div className="alert alert-danger alert-dismissible" role="alert">
                                <div>{state.releaseErrors}</div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default SuggestionCreator;