//React

import React from 'react';
import Slider from 'react-slick';
import { RiSearchLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//App
import { Loader, SuggestionCreator } from '../Components';
import { useScreenSize } from '../Utils';

const AsidePeopleComponent = ({
    customStyle,
    title,
    icon,
    expired,
    styleResp,
    creators,
    searchQuery,
    searchResults,
    searchLoad,
    isLoading,
    sliderRef,
    settings,
    setSearchQuery,
    handleRefreshClick,
    handlePrevious,
    handleNext
}) => {
    const { t } = useTranslation();
    const users = useSelector((state) => state.users);
    const screenSize = useScreenSize();
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium';

    return (
        //fix-sidebar
        <aside className={`${customStyle}`}>
            <div className={`${styleResp ? styleResp : 'lg-none'}`}>
                <div className={`overflow-auto`} style={{ maxHeight: styleResp ? '1000px' : '680px' }}>
                    {!styleResp && !expired &&
                        <form autoComplete='off' className={`bg-white input-group mb-4 border border-fw-light overflow-hidden py-2 `}>
                            <input type="text" value={searchQuery} style={{ marginLeft: '12px' }} onChange={(e) => setSearchQuery(e.target.value)} name="search" placeholder={`${t('navigation.findCreator')}`} className={`form-control border-0 fw-light ps-1 `} />
                            <span className={`bg-white input-group-text  border-0 fan_color`}> <RiSearchLine size={20} />  </span>
                        </form>
                    }
                    <div className={`bg-white border border-fw-light account-follow mb-1 ${styleResp ? 'border-top-0' : ''}`} >
                        <div className={`d-flex align-items-center justify-content-between border-bottom ${styleResp ? 'py-3  px-2 pt-0' : 'py-3 px-2 '}`}>
                            <h6 className={`fw-bold  mb-0 ${expired ? "text-danger" : "text-secondary"} ${styleResp ? 'ms-3' : ''}`}>{searchQuery.length > 0 ? searchQuery + " ?" : `${icon} ${title}`}</h6>
                            {creators.length > 0 && !expired &&
                                <div className="d-flex align-items-center me-0">
                                    <button className={`material-icons border-0 bg-transparent text-secondary m-0 p-0`} disabled={creators.length <= 2} onClick={handleRefreshClick}>cached</button>
                                    <button className={`material-icons border-0 bg-transparent  web-none m-0 p-0 ps-3 ${creators.length <= 2 ? 'disabled text-secondary opacity-50' : 'text-secondary'}`} disabled={creators.length <= 2} onClick={handlePrevious}>chevron_left</button>
                                    <button className={`material-icons border-0 bg-transparent web-none m-0 p-0 ps-3 ${creators.length <= 2 ? 'disabled text-secondary opacity-50' : 'text-secondary'}`} disabled={creators.length <= 2} onClick={handleNext}>navigate_next</button>
                                </div>
                            }
                        </div>
                        <div className='overflow-auto pb-0 mb-0' style={{ maxHeight: styleResp ? '500px' : '230px' }} >
                            {searchQuery.length > 0 && searchResults.length === 0 && !searchLoad ? (
                                <p className="text-center mt-2 mb-0">
                                    <span className='fw-bold'>{t('words.searching')}...{searchQuery}</span>
                                </p>
                            ) : searchQuery.length > 0 && searchResults.length > 0 && searchLoad ? (
                                searchResults.map((user, index) => (
                                    <SuggestionCreator suggestionRight={true} key={user._id} data={user} />
                                ))
                            ) : searchQuery.length > 0 && searchResults.length === 0 && searchLoad === true ? (
                                <p className="text-center mt-2 mb-0">
                                    <span className='fw-bold'>{searchQuery}</span>{t('container.aside_people.hasChanged')}!
                                </p>
                            ) : users.authenticated && users.access_token !== "" ?
                                isLoading ?
                                    <Loader styleColor={true} /> :
                                    creators.length > 0 ?
                                        <>
                                            {isMobile ?
                                                <Slider ref={sliderRef}  {...settings} className="web-none">
                                                    {creators.reduce((slides, creator, index) => {
                                                        if (index % 2 === 0) {
                                                            const subCreators = creators.slice(index, index + 2);
                                                            slides.push(
                                                                <div key={index} className="d-flex flex-column">
                                                                    {subCreators.map((subCreator, subIndex) => (
                                                                        <SuggestionCreator key={subCreator._id} suggestionRight={true} creatorLength={{ index: subIndex, length: creators.length }} data={expired ? subCreator.creator : subCreator} styleResp={styleResp} />
                                                                    ))}
                                                                </div>
                                                            );
                                                        }
                                                        return slides;
                                                    }, [])}
                                                </Slider>
                                                :
                                                <div className="m-none">
                                                    {creators.map((creator) => {
                                                        return (
                                                            <SuggestionCreator key={creator._id} suggestionRight={true} data={expired ? creator.creator : creator} />
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </>
                                        : <p className="text-center mt-2 mb-0">{t('container.aside_people.noRecommandations')}</p>
                                :
                                <p className="text-center text-danger mt-2 mb-0">{t('container.aside_people.youAreNotLoggedIn')}.</p>
                            }
                        </div>

                    </div>

                </div>


            </div>

        </aside>
    );
};

export default AsidePeopleComponent;