import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


import Avatar from './Avatar';
import { Images } from '../Constants';
import ValidationModal from './ValidationModal';
import { completeDateFormat, convertCurrency, getStatutByUser, shortenName, useScreenSize } from '../Utils';


const CardProfile = ({ fan, creator, subsciption, profile, addClassName, subsPlan }) => {


    const { t } = useTranslation();
    const _id = useParams();
    const params = useSelector((state) => state.params);
    const users = useSelector((state) => state.users);
    const [remainingTime, setRemainingTime] = useState(null);
    const [isOnLine, setIsOnLine] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const screenSize = useScreenSize()

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    let convert = convertCurrency(params?.currency, params?.currencies, subsciption?.subscription_fee, 'GET');

    let displayName = creator?.display_name;
    let pseudoName = creator?.pseudo;



    const isSubscriptionExpired = (endDate) => {
        const currentTime = new Date();
        const expirationDate = new Date(endDate);

        return currentTime > expirationDate;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const expirationDate = new Date(subsciption?.expiry_date);
            const currentTime = new Date();
            const difference = expirationDate - currentTime;

            if (difference <= 0) {
                setRemainingTime(null);
                clearInterval(intervalId);
            }
            else {
                const remainingDays = Math.floor(difference / (1000 * 60 * 60 * 24));
                const remainingHours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const remainingMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                setRemainingTime(`${remainingDays}${t('words.calander_days')} : ${remainingHours}H : ${remainingMinutes}M `);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [subsciption, t]);

    const isExpiringSoon = remainingTime && remainingTime.includes('J 00h 00m');

    useEffect(() => {
        if (users.users?._id !== creator?._id && creator._id !== undefined) {
            let online = getStatutByUser(creator?._id);
            online.then((value) => {
                setIsOnLine(value)
            });
        }
    }, [_id, users.access_token, users.users?._id, creator?._id]);

    function resize(name) {
        if (profile) {
            if (screenSize === "Extra Small") {
                return shortenName(name, 17)
            } else if (screenSize === "Small") {
                return shortenName(name, 25)
            } else if (screenSize === "Medium") {
                return shortenName(name, 10)
            } else if (screenSize === "Large") {
                return shortenName(name, 10)
            } else {
                return shortenName(name, 15)
            }

        } else {
            if (subsPlan) {
                return shortenName(name, 20);
            } else {
                return shortenName(name, 10);
            }

        }
    }

    return (
        <>
            <div className={`bg-white rounded-3 ${subsPlan ? "" : "shadow-sm"} profile overflow-hidden ${addClassName}`} style={{ position: 'relative', maxHeight: subsciption ? '' : '335px' }}>
                <div style={{ backgroundImage: `url(${creator?.banner === '' ? Images.background : creator?.banner})`, height: '120px', backgroundSize: 'cover' }}>
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, .3)', height: '120px' }}></div>
                </div>
                <NavLink to={creator?.account_type === 'CREATOR' && (users.users?._id === creator._id ? '/profile' : `/${creator.pseudo}`)} className=" text-decoration-none d-flex align-items-center ps-3 pt-1 ">
                    <div style={{ marginTop: -60, position: 'relative' }}>
                        <Avatar user={creator} width={80} height={80} fontSize="fs-2" />
                        {isOnLine && <span className="rounded-circle position-absolute bottom-0 mb-2" style={{ width: 14, height: 14, right: '4px', background: '#35D063', border: '2px solid white' }}></span>}
                    </div>
                    <div className="ms-2 mt-1" style={{ marginTop: -10 }}>
                        <h6 className="mb-0 d-flex align-items-center text-body fs-5 fw-bold text-capitalize">
                            <span className="m-0 p-0 small" style={{ fontSize: subsciption ? '' : '' }}>{resize(displayName)}</span>
                            {creator?.account_type === 'CREATOR' &&
                                <span className="ms-1 md-16 fw-bold mb-0">{Images.verif} </span>
                            }
                        </h6>
                        <p className="text-muted mb-0">@{resize(pseudoName)}</p>
                    </div>

                </NavLink>
                {(subsciption) &&
                    <div style={{ position: 'absolute', top: 5, right: 5 }}>
                        {subsciption.is_active ?
                            <>
                                <span className="badge alert-success">{t('views.follows.expired_on')} : {remainingTime}</span>
                                {isExpiringSoon &&
                                    <span className="badge alert-warning ms-2">{t('views.follows.expired_soon')}</span>
                                }
                            </>
                            :
                            <span className="badge alert-danger">{t('views.follows.expired')}</span>
                        }
                    </div>
                }

                {fan === false ?
                    <>
                        <div className="pb-2 mt-2">
                            {subsciption ?
                                <div className="ms-auto btn-group w-100 disabled my-2 px-3" tabIndex="-1" role="button" aria-disabled="true">
                                    {subsciption.is_active ?
                                        <button data-bs-toggle="modal" data-bs-target={`#validationModal${creator._id}`} className={`btn d-flex align-items-center justify-content-between btn-sm px-3 rounded-pill border-secondary fb-color`} htmlFor="btncheck1">
                                            <span className="fw-bold m-none">{convert?.length >= 5 ? `${t('words.subs')}` : `${t('words.subscribed')}`}</span>
                                            <span className="fw-bold web-none">{t('words.subscribed')}</span>

                                            {subsciption?.is_free === true ?
                                                <span className="fw-bold">{t('words.for_free')}</span> :
                                                <>
                                                    <span className="fw-bold m-none">
                                                        {convert}
                                                        {params.currency}/{convert?.length >= 8 ? 'M' : t('words.month')}
                                                    </span>
                                                    <span className="fw-bold web-none">
                                                        {convert}
                                                        {params.currency}/{t('words.month')}
                                                    </span>
                                                </>
                                            }
                                        </button>
                                        :
                                        <NavLink to={`/${creator.pseudo}`} className={`btn  border d-flex align-items-center justify-content-between btn-sm px-3 rounded-pill border-secondary text-danger`} htmlFor="btncheck1">
                                            {/* {(users.account.balance < creator.subscription_fee) &&
                                                <span data-bs-toggle="modal" data-bs-target={users.users?.verified ? `#sentTipModal${id}` : '#checkMailModal'} ref={releaseRef}></span>
                                            } */}
                                            <span className="fw-bold" >{t('words.subscribe')}</span>
                                            {subsciption?.is_free === true ?
                                                <span className="fw-bold">{t('words.for_free')}</span> :
                                                <>
                                                    <span className="fw-bold m-none">
                                                        {convert}
                                                        {params.currency}/{convert?.length >= 8 ? 'M' : t('words.month')}
                                                    </span>
                                                    <span className="fw-bold web-none">
                                                        {convert}
                                                        {params.currency}/{t('words.month')}
                                                    </span>
                                                </>
                                            }
                                        </NavLink>
                                    }

                                </div>
                                :
                                <div className={`${subsPlan ? "px-3 pt-3 pb-0" : "p-3 pt-1"}`}>
                                    {subsPlan && <h5 className="text-uppercase fw-bold opacity-75" style={{ fontSize: '1.1em' }}>{t('views.follows.subscribeAndEnjoy')}:</h5>}
                                    <div className="d-flex flex-column gap-1">
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="material-icons md-24 fb-color">check</span>
                                            <span className="text-black" style={{ fontSize: '1em' }}>{t('views.follows.accessToContent')}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="material-icons md-24 fb-color">check</span>
                                            <span className="text-black" style={{ fontSize: '1em' }}>{t('views.follows.directMessage')}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="material-icons md-24 fb-color">check</span>
                                            <span className="text-black" style={{ fontSize: '1em' }}>{t('views.follows.cancelYourSubscription')}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className="accordion px-3 pt-3">
                            <button className={`accordion-header btn w-100 p-0 d-flex align-items-center justify-content-between  ${isOpen ? 'border border-light bg-light p-2 pb-0' : 'border-0  bg-transparent '}`} onClick={toggleAccordion}>
                                <h6 className="mb-3">{t('views.follows.subscription_details')}</h6>
                                <span className="material-icons mb-3">{isOpen ? 'expand_less' : 'expand_more '}</span>
                            </button>
                            {isOpen && (
                                <div className="accordion-content">
                                    <div className="d-flex align-items-center justify-content-between border-bottom py-1">
                                        <span>{t('views.follows.current_subscription')}</span>
                                        <span className="fw-bold">
                                            {subsciption?.is_free === true ? 0 : convert} {params.currency}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-2 border-bottom py-1">
                                        <span>{t('views.follows.started')}</span>
                                        <span className="fw-bold">{completeDateFormat(subsciption?.subscription_date, t)}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between border-bottom py-1">
                                        <span>{t('views.follows.expires')}</span>
                                        <span className={!isSubscriptionExpired(subsciption?.expiry_date) ? "fw-bold" : "fw-bold text-danger"}>
                                            {!isSubscriptionExpired(subsciption?.expiry_date) ? remainingTime : `${t('words.expired')}`}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between border-bottom py-1">
                                        <span>{t('views.follows.rebill')}</span>
                                        <span className={subsciption?.rebill ? "fw-bold" : "fw-bold text-danger"}>
                                            {subsciption?.rebill ? `${t('words.activated')}` : `${t('words.deactivated')}`}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>

                    </>
                }
            </div >
            {subsciption &&
                <ValidationModal id={creator._id} pseudo={creator.pseudo} profile={false} />
            }
        </>
    )
}

export default CardProfile;