import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Routes, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { convertCurrency, getBookmark, getCreatorFollows, getLikes, getReleasePost, getStatutByUser, getSubscriptionFees, useScreenSize, restore_numbers_in_filename } from '../../Utils';
import { RequestFanbusy, TchatRequest } from "../../Services";
import { AppContainer, AsideContainer } from "../../Containers";
import { Avatar, Button, DescriptionParagraph, EndOfPage, Loader, NoContent, Post, ShareModal, StickyHeaderOnScroll, SubcriptionPlans, SubscriptionsPlansModal, TransactionsModal, ValidationModal } from "../../Components";
import { Images } from "../../Constants";
import LogOrSignToSubscribeView from "./LogOrSignToSubscribView";
import CheckMailModalView from "./CheckMailModalView";
import { MdOutlineChat } from 'react-icons/md';
import { MdRedeem } from 'react-icons/md';
// import { MdLocalAtm } from "react-icons/md";
import { shortenName } from "../../Utils";
// import CollectionsContainer from "../../feature/collections/collectionsContainer";


const ProfileViewByCreator = () => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const refCloseSubscriptionPlansModal = useRef(null);
    const baseLink = 'https://dyu0tgtvswtj8.cloudfront.net/fanbusy_'
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const releaseRef = React.useRef(null);
    const sref = React.useRef(null);
    const [isLoading, setLoading] = React.useState(true);
    const [key, setKey] = React.useState(0);
    const [completedRequests, setCompletedRequests] = React.useState(1);
    const [state, setState] = React.useState({
        releaseErrors: '',
        posts: [],
        creatorData: {},
        collections: [],
        loadchat: false,
        isLoading: false,
        isLoadingFirstPost: true,
        isLoadingMorePost: false,
        isLoading3Months: false,
        isLoading12Months: false,
        isLoading6Months: false,
        isLoadingCollection: true,
    });
    const [follows, setFollows] = useState([])
    const [fee, setFee] = useState({})

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 5,
        last_page: 0,
        current_page: 0,
    })

    const nextPage = pagination.current_page + 1;

    const [listBookmark, setListBookmark] = React.useState([]);
    const [listLikes, setListLikes] = React.useState([]);
    const [listPostRelease, setListPostRelease] = React.useState([]);
    const [scrollY, setScrollY] = useState(0);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const [showReleaseError, setShowReleaseError] = React.useState(true);
    const [isOnLine, setIsOnLine] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    // const [activeTab, setActiveTab] = React.useState("Posts");
    const [subscriptionFee, setSubscriptionFee] = useState(0);
    const [period, setPeriod] = useState(0);


    if (id === users.users?.pseudo) {
        const myProfile = '/profile'
        navigate(myProfile);
    }



    const checkIfSubscribeToCreator = (creator_id) => {
        if (follows?.length > 0) {
            let followId = follows?.filter((follow) => follow.fan?._id === creator_id || follow.creator?._id === creator_id)
            if (followId.length > 0) {
                return true
            }
            return false
        }
    }

    const getPostByCreatorId = React.useCallback(async (creator_id) => {
        let res = await RequestFanbusy(`posts/creator/${creator_id}`, 'GET', '', users.access_token);

        if (res.status === 200) {
            const paginage = res.response.paginage;
            const modifiedData = res.response.data.map(post => {
                return {
                    ...post,
                    illustrations: post.illustrations.map(illustration => {
                        if (illustration.link.startsWith('http')) {
                            return illustration;
                        } else {
                            return {
                                ...illustration,
                                link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                            };
                        }
                    })
                };
            });
            setPagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
            setState((prev) => ({ ...prev, posts: modifiedData, isLoadingFirstPost: false }));
        }
        setCompletedRequests(prev => prev + 1);
    }, [users.access_token]);

    const loadMorePost = useCallback(async () => {
        try {
            setState(prevState => ({ ...prevState, isLoadingMorePost: true }));
            const res = await RequestFanbusy(`posts/creator/${state.creatorData?._id}?skip=${nextPage}&limit=${pagination.per_page}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const data = res.response.data.map(post => {
                    return {
                        ...post,
                        illustrations: post.illustrations.map(illustration => {
                            if (illustration.link.startsWith('http')) {
                                return illustration;
                            } else {
                                return {
                                    ...illustration,
                                    link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                                };
                            }
                        })
                    };
                });
                const paginage = res.response.paginage;
                setPagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
                setTimeout(() => {
                    setState((prevState) => ({ ...prevState, posts: [...prevState.posts, ...data], isLoadingMorePost: false }));
                }, 2000);
            }
        } catch (error) {
            setState(prevState => ({ ...prevState, isLoadingMorePost: false }));
        }
    }, [nextPage, pagination.per_page, state.creatorData?._id, users.access_token]);

    const getCreatorById = React.useCallback(async () => {
        let res = await RequestFanbusy(`creators/u/${id}`, 'GET', '', users.access_token);
        if (res.status === 200) {
            const creator = res.response?.data
            setState((prev) => ({ ...prev, creatorData: creator }));
            setDisabled(!checkIfSubscribeToCreator(state.creatorData?._id) ? 'pe-none' : '')
            getPostByCreatorId(res.response.data?._id)
            setFee(getSubscriptionFees(creator?.subscriptions_details, params.continent))
        }
        setCompletedRequests(prev => prev + 1);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPostByCreatorId, id, state.creatorData?._id, users.access_token]);

    const subscribeToCreator = async (subscriptionPeriod) => {
        setPeriod(subscriptionPeriod)
        const subscriptionFees = {
            1: fee.monthly,
            3: fee.quarterly,
            6: fee.halfYearly,
            12: fee.yearly
        };
        const form = {
            creator_id: state.creatorData?._id,
        };

        if (!checkIfSubscribeToCreator(state.creatorData?._id)) {
            const requiredFee = subscriptionFees[subscriptionPeriod];
            setSubscriptionFee(requiredFee);
            if (users.account?.balance < requiredFee) {
                if (releaseRef) {
                    console.log('ref');
                    releaseRef.current?.click();
                }

                refCloseSubscriptionPlansModal && refCloseSubscriptionPlansModal.current.click()

            } else {

                setState((prev) => ({
                    ...prev,
                    isLoading: subscriptionPeriod === 1 ? true : prev.isLoading,
                    isLoading3Months: subscriptionPeriod === 3 ? true : prev.isLoading3Months,
                    isLoading6Months: subscriptionPeriod === 6 ? true : prev.isLoading6Months,
                    isLoading12Months: subscriptionPeriod === 12 ? true : prev.isLoading12Months
                }));

                let res = await RequestFanbusy(`souscriptions/?continent=${params.continent}&subscription_period=${subscriptionPeriod}`, 'POST', form, users.access_token);
                if (res.status === 201) {
                    let follows = getCreatorFollows(users.access_token);
                    setShowReleaseError(true);
                    follows.then((value) => {
                        setState((prev) => ({
                            ...prev,
                            releaseErrors: t('views.profile.subscribingToThisCreatorWasSuccess'),
                            isLoading: false,
                            isLoading3Months: false,
                            isLoading6Months: false,
                            isLoading12Months: false
                        }));
                        setFollows(value);
                    });
                    setKey(key => key + 1)
                    getCreatorById();
                } else {
                    let errorMessage = '';
                    if (res.status === 400) {
                        if (res.response.response_type === "Insuffisant fund") {
                            errorMessage = t('errors_messages.insuffisant_fund');
                        } else if (res.response.response_type === "Bad Request") {
                            errorMessage = res.response.description;
                        }
                    } else {
                        errorMessage = t('navigation.tryAgain');
                    }

                    setShowReleaseError(true);
                    setState((prev) => ({
                        ...prev,
                        releaseErrors: errorMessage,
                        isLoading: false,
                        isLoading3Months: false,
                        isLoading6Months: false,
                        isLoading12Months: false
                    }));
                }
            }
        }
    };
    const handleDataReady = (data) => {
        setShowReleaseError(true)
        setState((prev) => ({ ...prev, releaseErrors: data }));
    };

    const convertAmount = (amount) => {
        let convert = convertCurrency(params.currency, params.currencies, amount, 'GET');
        return convert;
    }

    React.useEffect(() => {
        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setFollows(value)
        });
        let bookmarks = getBookmark(users.access_token);
        bookmarks.then((value) => {
            setListBookmark(value);
        });
        let likes = getLikes(users.access_token, 'POST');
        likes.then((value) => {
            setListLikes(value);
        });

        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setListPostRelease(value);
        });

        getCreatorById();

        // const getCollectionBycreator = async () => {
        //     let res = await RequestFanbusy(`collection/creator/${id}`, 'GET', '', users.access_token);
        //     // const pagination = res.response.paginage;
        //     console.log('myyyyyyyyyyyyyyyCollection', res.response.data);
        //     if (res.status === 200) {
        //         setState((prev) => ({ ...prev, collections: res.response.data, isLoadingCollection: false }));
        //         // setPagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));

        //     }
        // }
        // getCollectionBycreator();

    }, [users.access_token, getCreatorById, id]);

    React.useEffect(() => {
        let online = getStatutByUser(id);
        online.then((value) => {
            setIsOnLine(value)
        });

        setCompletedRequests(0);

        if (state.releaseErrors?.length > 0) {
            const timer = setInterval(() => {
                setState((prev) => ({ ...prev, releaseErrors: '' }));
            }, 10000);

            return () => clearInterval(timer);
        }
    }, [state.releaseErrors.length, id]);

    React.useEffect(() => {
        if (completedRequests === 2) {
            setLoading(false);
        }
    }, [completedRequests]);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        let isFetching = false;

        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            const scrollPosition = scrollTop + clientHeight;
            const scrollThreshold = scrollHeight - 500;

            if (!isFetching && scrollPosition >= scrollThreshold && pagination.current_page !== pagination.last_page && state.posts.length >= 5) {
                isFetching = true;
                loadMorePost();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadMorePost, pagination.current_page, pagination.last_page, state.posts.length]);


    const createChatWithCreator = async (creator_id) => {
        setState(prevState => ({ ...prevState, loadchat: true }));
        const creatorResponse = await RequestFanbusy(`creators/${creator_id}`, 'GET', '', users.access_token);
        const newCreatorObject = creatorResponse.response.data;
        try {
            let data = {
                sender: {
                    id: users.users._id,
                },
                receiver: {
                    id: newCreatorObject._id,
                }
            }
            const response = await TchatRequest('room', 'POST', data, '');
            if (response.response.status_code === 201) {
                const newRoom = response.response.data?._id;
                const res = await TchatRequest(`room/Room/souscriptions/${users.users?._id}/${creator_id}`, 'PUT', '', '')
                if (res.response.status_code === 200) {
                    navigate(`/messages/my/chat/${newRoom}/${newCreatorObject._id}`)
                }
            } else {
                const roomexist = response.response.data[0]?._id;
                navigate(`/messages/my/chat/${roomexist}/${newCreatorObject._id}`)
            }
            setState(prevState => ({ ...prevState, loadchat: false }));

        } catch (error) {
            setState(prevState => ({ ...prevState, errorType: error.message, loadchat: false }));
        }
    }

    // const changeTab = (tab) => {
    //     setActiveTab(tab);
    // };

    // async function deleteCollection(collectionId) {
    //     setState((prev) => ({
    //         ...prev, collections: state.collections.filter(collection => collection.id !== collectionId)
    //     }))
    //     RequestFanbusy(`collection/${collectionId}`, 'DELETE', "", users.access_token);


    // }


    return (
        <>
            <AppContainer>
                <main key={key} style={{ paddingBottom: isMobile ? '30px' : '0px', }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 border border-top-0 border-fw-light px-0  border-bottom-0  ${isMobile && ' pb-5 '}`}>
                    {isLoading ?
                        <Loader styleColor={true} marginTop={true} /> :
                        <div className="main content">
                            {scrollY >= 260 && (
                                <StickyHeaderOnScroll
                                    creator={state.creatorData}
                                    id={id} disabled={disabled}
                                    createChatWithCreator={createChatWithCreator}
                                    checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                    loadchat={state.loadchat}
                                />
                            )}
                            <div className={`${darkMode ? 'bg-dark' : 'bg-white'} border-bottom profile`} style={{ position: 'relative' }}>
                                <div style={{ backgroundImage: `url(${state.creatorData?.banner.length === 0 ? Images.background : state.creatorData?.banner})`, backgroundSize: 'cover' }}>
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, .10)', height: '200px', }}>
                                        <div className={`d-flex flex-column gap-2 pt-2 ps-3`}>
                                            <div className={` d-flex align-items-center `}>
                                                <a href={`/`} className={`text-white material-icons text-decoration-none ms-0`}>arrow_back</a>
                                                <p className={`text-white ms-2 mb-0 fw-bold mt-0 fs-6 text-capitalize d-flex align-items-center`}>
                                                    <span className="m-0 p-0 ">{shortenName(state.creatorData?.display_name, 28)}</span>
                                                    {state.creatorData?.account_type === 'CREATOR' &&
                                                        <span className="mb-0 ms-1 pb-1 md-16 fw-bold " style={{ marginTop: '2px' }}>{Images.verifWhite} </span>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center  px-3 pt-1">
                                    <div style={{ marginTop: -50, position: 'relative' }}>
                                        <Avatar user={state.creatorData} width={80} height={80} fontSize={'fs-4'} />
                                        {isOnLine &&
                                            <span className="rounded-circle position-absolute bottom-0 mb-2" style={{ width: 14, height: 14, right: '4px', background: '#35D063', border: '2px solid white' }}></span>
                                        }
                                    </div>

                                    <div className=" ms-auto d-flex align-items-end">
                                        {users.users?._id !== id &&
                                            <button title="Send Tip" data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} className={`border rounded-pill ms-2 text-decoration-none bg-white ${checkIfSubscribeToCreator(state.creatorData?._id) ? 'fb-color' : 'text-secondary opacity-50 pe-none '}`} >
                                                <span ><MdRedeem size={27} /></span>
                                            </button>
                                        }
                                        <button onClick={() => { createChatWithCreator(state.creatorData?._id) }} title="Messages" className={`border rounded-pill ms-2 text-decoration-none bg-white  ${checkIfSubscribeToCreator(state.creatorData?._id) ? 'fb-color' : 'text-secondary opacity-50 pe-none'} `} >
                                            {state.loadchat ?

                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                :
                                                <span ><MdOutlineChat size={27} /></span>
                                            }
                                        </button>

                                    </div>
                                </div>
                                <div className="pb-2 pt-1 px-3" style={{ boxShadow: "0px 5px 0px #EDF2F6" }}>
                                    <div className="mb-2">
                                        <h6 className={`${darkMode ? 'text-white' : 'text-body'} mb-0 d-flex align-items-center fs-6 fw-bold text-capitalize`}>
                                            <span className="p-0 m-0">{shortenName(state.creatorData?.display_name, 28)}</span>
                                            {state.creatorData?.account_type === 'CREATOR' &&
                                                <span className="ms-1 p-0 md-16 fw-bold " style={{ marginBottom: '4px' }}>{Images.verif} </span>
                                            }
                                        </h6>
                                        <p className="text-muted mb-0">@{shortenName(state.creatorData?.pseudo, 28)}</p>
                                    </div>
                                    <DescriptionParagraph bio={state.creatorData?.bio} profile={true} />
                                </div>

                                <div className="p-3 pt-1 mt-3" style={{ boxShadow: "0px 5px 0px #EDF2F6" }}>
                                    <h6 className="text-uppercase">{t('navigation.subscription')}</h6>
                                    <div className="ms-auto btn-group w-100 disabled" tabIndex="-1" role="button" aria-disabled="true">
                                        <input type="checkbox" className="btn-check" id="btncheck1" />

                                        {(users.authenticated && users.access_token !== "") ?
                                            <div className="w-100">
                                                <div className="w-100 pb-2" style={{ boxShadow: (!checkIfSubscribeToCreator(state.creatorData?._id) && fee.hasPaymentPlan) && "0px 5px 0px #EDF2F6" }}>
                                                    <Button
                                                        addClassName={'mb-2'}
                                                        checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                                        isLoading={state.isLoading}
                                                        creator={state.creatorData}
                                                        subscribeToCreator={subscribeToCreator}
                                                        t={t}
                                                        id={id}
                                                        amount={convertAmount(fee.monthly)}
                                                        releaseRef={releaseRef}
                                                        sref={sref}
                                                        time={t('words.month')}
                                                        text={t('words.subscribe')}
                                                        subscriptionPeriod={1}
                                                        key={0}
                                                    />
                                                </div>

                                                {!checkIfSubscribeToCreator(state.creatorData?._id) && (fee.hasPaymentPlan) &&
                                                    <SubcriptionPlans
                                                        checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                                        isLoading={state.isLoading}
                                                        creator={state.creatorData}
                                                        subscribeToCreator={subscribeToCreator}
                                                        t={t}
                                                        id={id}
                                                        amountForTreMonth={convertAmount(fee.quarterly)}
                                                        amountForSixMonth={convertAmount(fee.halfYearly)}
                                                        amountForTwelvfMonth={convertAmount(fee.yearly)}
                                                        releaseRef={releaseRef}
                                                        isLoading3Months={state.isLoading3Months}
                                                        isLoading6Months={state.isLoading6Months}
                                                        isLoading12Months={state.isLoading12Months}
                                                        sref={sref}
                                                        key={6}
                                                        title={true}
                                                        addClassParent={'mt-3'}
                                                    />
                                                }
                                            </div>
                                            :
                                            <a href="/" data-bs-toggle="modal" data-bs-target={`#signOrLogModal${id}`} className={`btn d-flex align-items-center justify-content-between btn-sm px-3 rounded-pill text-decoration-none  ${!checkIfSubscribeToCreator(state.creatorData?._id) ? 'gradient-button text-white' : 'border-secondary fb-color'}`} htmlFor="btncheck1">
                                                <span className="fw-bold ">{checkIfSubscribeToCreator(state.creatorData?._id) ? `${t('words.subscribed')}` : `${t('words.subscribe')}`}</span>
                                                {state.creatorData.is_free_account ?
                                                    <span className="fw-bold">{t('views.profile.forFree')}</span>
                                                    :
                                                    <span className="fw-bold" >{convertAmount(state.creatorData?.subscription_fee)} {params.currency}/{t('views.profile.month')}</span>
                                                }
                                            </a>
                                        }

                                    </div>


                                    {/* <div >
                                        <ul className="nav nav-pills justify-content-center nav-justified overflow-hidden" id="pills-tab" role="tablist">
                                            <li className="nav-item m-2 mb-0 ms-0" role="presentation" onClick={() => { }}>
                                                <button className={`nav-link pe-10 text-dark ${activeTab === "Posts" ? "active bg-transparent fw-bold" : ""}`} id="pills-feed-tab" data-bs-toggle="pill" data-bs-target="#pills-feed" type="button" role="tab" aria-controls="pills-feed" aria-selected={activeTab === "YOUR CARDS"} onClick={() => changeTab("Posts")}>
                                                    <span className="text-gradient fw-bold fs-6"> Posts</span>
                                                </button>
                                                {activeTab === "Posts" &&
                                                    <span className="gradient-button d-block mb-0" style={{ width: "100%", height: 3 }}></span>
                                                }
                                            </li>
                                            <li className="nav-item m-2 mb-0 me-0" role="presentation" onClick={users.users?.account_type === 'CREATOR' ? () => { } : () => { }}>
                                                <button className={`nav-link  ${activeTab === "Collections" ? "active bg-transparent fw-bold" : ""}`} id="pills-people-tab" data-bs-toggle="pill" data-bs-target="#pills-people" type="button" role="tab" aria-controls="pills-people" aria-selected={activeTab === "Collections"} onClick={() => changeTab("Collections")} >
                                                    <span className="text-gradient fw-bold fs-6">Collections</span>
                                                </button>
                                                {activeTab === "Collections" &&
                                                    <span className="gradient-button d-block" style={{ width: "100%", height: 3 }}></span>
                                                }
                                            </li>
                                        </ul>
                                    </div> */}

                                    {(state.releaseErrors.length > 0 && showReleaseError) &&
                                        <div className={`alert alert-${(state.releaseErrors.includes('suc') || state.releaseErrors.includes('Suc') || state.releaseErrors.includes('réussi')) ? 'success' : 'danger'} alert-dismissible mb-0 mt-2`} role="alert">
                                            <div>{state.releaseErrors}</div>
                                            <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                                        </div>
                                    }
                                </div>

                                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                    <div className="dropdown">
                                        <a href={`/`} className="text-white text-decoration-none material-icons ms-2 md-20 rounded-circle bg-transparent p-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_vert</a>
                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">

                                            <li>
                                                <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${id}`}>
                                                    <span className="material-icons md-13 me-1">share</span>{t('views.profile.shareViaAnotherApps')}
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <section className="pt-4 feeds pb-3">
                                {state.isLoadingFirstPost ?
                                    <Loader styleColor={true} marginTop={true} /> :
                                    <>
                                        {state.posts?.length > 0 ?
                                            state.posts?.map((post, index) => {
                                                return (
                                                    <Post
                                                        key={post._id}
                                                        postLength={{ length: state.posts?.length, index: index }}
                                                        post={post}
                                                        creatorId={state.creatorData?._id}
                                                        bookmarks={listBookmark}
                                                        likes={listLikes}
                                                        released={listPostRelease}
                                                        follows={follows}
                                                        setListPostRelease={setListPostRelease}
                                                        fee={fee}
                                                        setFollows={setFollows}
                                                        setPeriod={setPeriod}
                                                    />
                                                )
                                            }
                                            ) : <NoContent message={`${t('views.profile.youHaveNoPublications')}`} />

                                        }
                                    </>}

                                {(state.isLoadingMorePost) && (
                                    <div className="text-center  text-muted my-3 fw-bold fs-6">
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="visually-hidden small">Loading...</span>
                                        </div>
                                    </div>

                                )}
                                {(pagination.current_page === pagination.last_page && !state.isLoadingMorePost && state.posts.length >= 2) &&
                                    <EndOfPage />
                                }
                            </section>

                            {/* {activeTab === "Posts" ?
                                <></>
                                :

                                //Collections

                                <section>
                                    <CollectionsContainer
                                        addClassName={'mt-2'}
                                        collections={state.collections}
                                        released={listPostRelease}
                                        follows={follows}
                                        setListPostRelease={setListPostRelease}
                                        deleteCollection={deleteCollection}
                                        isLoading={state.isLoadingCollection}
                                        fee={fee}
                                        subscribeToCreator={subscribeToCreator}
                                    />
                                </section>

                            } */}

                        </div>
                    }
                </main>

                <AsideContainer />

            </AppContainer>

            <ShareModal id={id} share={t('navigation.shareProfile')} postId={false} />
            {users.authenticated && users.access_token !== "" ?
                <ValidationModal id={state.creatorData?._id} pseudo={id} profile={true} />
                :
                <LogOrSignToSubscribeView id={id} creator={state?.creatorData} />
            }
            {users.users?.verified ?
                <TransactionsModal
                    onDataReady={handleDataReady}
                    data={{ id: state.creatorData?._id, photo: state.creatorData?.photo, account_type: state.creatorData?.account_type, display_name: state.creatorData?.display_name, pseudo: state.creatorData?.pseudo, postId: id }}
                    action={!checkIfSubscribeToCreator(state.creatorData?._id) ? 'subscribeCreator' : 'leaveTip'}
                    nameForm={!checkIfSubscribeToCreator(state.creatorData?._id) ? `${t('words.subscription')}` : `${t('navigation.leaveTip')}`}
                    amount={(!checkIfSubscribeToCreator(state.creatorData?._id) && !state.creatorData?.is_free_account) ? convertCurrency(params.currency, params.currencies, subscriptionFee, 'GET')?.replace(/\s/g, '') : 0}
                    setListPostRelease={setListPostRelease}
                    setFollows={setFollows}
                    period={period}
                />

                :

                <CheckMailModalView />
            }
            <SubscriptionsPlansModal
                checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                isLoaded={state.isLoaded}
                creator={state.creatorData}
                subscribeToCreator={subscribeToCreator}
                t={t}
                pseudo={id}
                amountForTreMonth={convertAmount(fee?.quarterly)}
                amountForSixMonth={convertAmount(fee?.halfYearly)}
                amountForTwelvfMonth={convertAmount(fee?.yearly)}
                releaseRef={releaseRef}
                isLoading3Months={state.isLoading3Months}
                isLoading6Months={state.isLoading6Months}
                isLoading12Months={state.isLoading12Months}
                refCloseSubscriptionPlansModal={refCloseSubscriptionPlansModal}
            />
        </>
    )
}

export default ProfileViewByCreator;